@use '@angular/material' as mat;
@import "@angular/material";

@mixin dq-user-schedule-component-theme($theme) {
  
  
  
  
  

  .user-schedule-weekly-component {
    .schedule-event-div {
      background-color: var(--sys-primary);
      color: var(--sys-surface-container-high);
    }
    .schedule-event-div:hover {
      background-color: var(--sys-primary);
    }
  }
}
