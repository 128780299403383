@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$_palettes: (
  primary: (
    0: #FFFFFF,
    10: #f6fff0,
    20: #ebffe6,
    25: #c8ffc4,
    30: #baf0b6,
    35: #9fd49c,
    40: #84b883,
    50: #6a9d6a,
    60: #518252,
    70: #39693b,
    80: #2d5c30,
    90: #215026,
    95: #14441b,
    98: #063911,
    99: #002106,
    100: #000000
  ),
  secondary: (
    0: #FFFFFF,
    10: #f6fff0,
    20: #ebffe5,
    25: #e1f7db,
    30: #d2e9cd,
    35: #b7cdb2,
    40: #9cb198,
    50: #81967e,
    60: #687c65,
    70: #50634e,
    80: #445742,
    90: #394b37,
    95: #2d402c,
    98: #233522,
    99: #0e1f0e,
    100: #000000
  ),
  tertiary: (
    0: #FFFFFF,
    10: #FFFBFF,
    20: #FFF8F6,
    25: #FFEDE6,
    30: #FFDBCA,
    35: #FFB68F,
    40: #F89155,
    50: #D8773E,
    60: #B85E27,
    70: #99470F,
    80: #893B02,
    90: #773200,
    95: #652A00,
    98: #542100,
    99: #331100,
    100: #000000
  ),
  neutral: (
    0: #FFFFFF,
    10: #fbfdf6,
    20: #f9faf3,
    25: #f0f1eb,
    30: #e1e3dd,
    35: #c5c7c1,
    40: #aaaca6,
    50: #8f918c,
    60: #5c5f5a,
    70: #454843,
    80: #393c38,
    90: #2e312d,
    95: #191c18,
    98: #0d0e0c,
    99: #060705,
    100: #000000
  ),
  neutral-variant: (
    0: #FFFFFF,
    10: #f7fff2,
    20: #f4fcef,
    25: #ecf3e6,
    30: #dde5d8,
    35: #c1c9bd,
    40: #a6ada2,
    50: #8b9388,
    60: #596057,
    70: #4d544b,
    80: #363d35,
    90: #2b322a,
    95: #171d16,
    98: #0d0e0b,
    99: #060705,
    100: #000000
  ),
  error: (
    0: #FFFFFF,
    10: #fff8f7,
    20: #ffecee,
    25: #ffd9df,
    30: #ffb1c1,
    35: #ff85a3,
    40: #ff4c85,
    50: #e3216b,
    60: #bb0054,
    70: #a50049,
    80: #90003e,
    90: #7a0034,
    95: #66002a,
    98: #3f0017,
    99: #1f000a,
    100: #000000
  )
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);

$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$m3-dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    brand-family: 'Segoe UI',
    bold-weight: 900,
    use-system-variables: true,
  ),
  density: (
    scale: 0
  )
));

.dark-theme {
  --sys-color-scheme: dark;

  --sys-primary: rgb(159 212 156);
  --sys-surface-tint: rgb(159 212 156);
  --sys-on-primary: rgb(6 57 17);
  --sys-primary-container: rgb(33 80 37);
  --sys-on-primary-container: rgb(186 240 182);
  --sys-secondary: rgb(185 204 180);
  --sys-on-secondary: rgb(37 52 36);
  --sys-secondary-container: rgb(59 75 57);
  --sys-on-secondary-container: rgb(213 232 207);
  --sys-tertiary: rgb(161 206 213);
  --sys-on-tertiary: rgb(0 54 60);
  --sys-tertiary-container: rgb(31 77 83);
  --sys-on-tertiary-container: rgb(188 235 241);
  --sys-error: rgb(255 180 171);
  --sys-on-error: rgb(105 0 5);
  --sys-error-container: rgb(147 0 10);
  --sys-on-error-container: rgb(255 218 214);
  --sys-background: rgb(16 20 15);
  --sys-on-background: rgb(224 228 219);
  --sys-surface: rgb(16 20 15);
  --sys-on-surface: rgb(224 228 219);
  --sys-surface-variant: rgb(66 73 64);
  --sys-on-surface-variant: rgb(194 201 189);
  --sys-outline: rgb(140 147 136);
  --sys-outline-variant: rgb(66 73 64);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(224 228 219);
  --sys-inverse-on-surface: rgb(45 50 44);
  --sys-inverse-primary: rgb(57 105 59);
  --sys-primary-fixed: rgb(186 240 182);
  --sys-on-primary-fixed: rgb(0 33 6);
  --sys-primary-fixed-dim: rgb(159 212 156);
  --sys-on-primary-fixed-variant: rgb(33 80 37);
  --sys-secondary-fixed: rgb(213 232 207);
  --sys-on-secondary-fixed: rgb(16 31 16);
  --sys-secondary-fixed-dim: rgb(185 204 180);
  --sys-on-secondary-fixed-variant: rgb(59 75 57);
  --sys-tertiary-fixed: rgb(188 235 241);
  --sys-on-tertiary-fixed: rgb(0 31 35);
  --sys-tertiary-fixed-dim: rgb(161 206 213);
  --sys-on-tertiary-fixed-variant: rgb(31 77 83);
  --sys-surface-dim: rgb(16 20 15);
  --sys-surface-bright: rgb(54 58 52);
  --sys-surface-container-lowest: rgb(11 15 10);
  --sys-surface-container-low: rgb(24 29 23);
  --sys-surface-container: rgb(28 33 27);
  --sys-surface-container-high: rgb(39 43 37);
  --sys-surface-container-highest: rgb(50 54 48);

  --dq-neutral-variant: rgb(38, 43, 36);
  --dq-inactive-contrast: rgb(44, 50, 42);
  --dq-bright-contrast: rgb(54 58 52);
  --dq-box-shadow-color: rgba(255, 255, 255, 0.2);

  --dq-app-color-background: #1E1E1E; /* Dark grey */
  --dq-app-color-warning: #4A4530; /* Muted yellow */
  --dq-app-color-surface: #2E2A27; /* Warm brown */
  --dq-app-color-error: #4A2A2A; /* Muted red */
  --dq-app-color-success: #29432A; /* Dark green */
  --dq-app-color-accent: #334D33; /* Brighter green */
}
