@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$_palettes-purple-dark: (
  primary: (
    0: #FFFFFF,
    10: #FFF7FF,
    20: #F9EDFF,
    25: #EEDBFF,
    30: #DAB9FF,
    35: #C497FA,
    40: #A97CDD,
    50: #8E63C1,
    60: #7449A6,
    70: #673D99,
    80: #5B308C,
    90: #4F2380,
    95: #431574,
    98: #2A0053,
    99: #190033,
    100: #000000
  ),
  secondary: (
    0: #FFFFFF,
    10: #FFF7FF,
    20: #F9EDFF,
    25: #EDDDF9,
    30: #D0C1DC,
    35: #B4A6C0,
    40: #998CA5,
    50: #7F728B,
    60: #655A71,
    70: #594E65,
    80: #4D4259,
    90: #41374D,
    95: #362C41,
    98: #21172B,
    99: #120D1A,
    100: #000000
  ),
  tertiary: (
    0: #FFFFFF,
    10: #FFF8F7,
    20: #FFECEE,
    25: #FFD9DE,
    30: #F6B6BF,
    35: #D89BA4,
    40: #BB818A,
    50: #9E6871,
    60: #825059,
    70: #75454D,
    80: #673942,
    90: #5A2E36,
    95: #4D232C,
    98: #340F17,
    99: #1E070C,
    100: #000000
  ),
  neutral: (
    0: #FFFFFF,
    10: #FFFBFF,
    20: #FEF7FC,
    25: #F5EFF4,
    30: #E7E1E5,
    35: #CAC5C9,
    40: #AFAAAE,
    50: #948F94,
    60: #7A767A,
    70: #615D61,
    80: #555155,
    90: #49464A,
    95: #3D3A3E,
    98: #322F33,
    99: #1D1B1E,
    100: #000000
  ),
  neutral-variant: (
    0: #FFFFFF,
    10: #FFFBFF,
    20: #FFF7FF,
    25: #F7EEF9,
    30: #E8E0EB,
    35: #CCC4CF,
    40: #B0A9B3,
    50: #958E98,
    60: #7B757F,
    70: #625C66,
    80: #56505A,
    90: #4A454E,
    95: #3E3A42,
    98: #332F37,
    99: #1E1A22,
    100: #000000
  ),
  error: (
    0: #000000,
    10: #3f0017,
    20: #66002a,
    25: #7a0034,
    30: #90003e,
    35: #a50049,
    40: #bb0054,
    50: #e3216b,
    60: #ff4c85,
    70: #ff85a3,
    80: #ffb1c1,
    90: #ffd9df,
    95: #ffecee,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest-purple-dark: (
  secondary: map.get($_palettes-purple-dark, secondary),
  neutral: map.get($_palettes-purple-dark, neutral),
  neutral-variant: map.get($_palettes-purple-dark, neutral-variant),
  error: map.get($_palettes-purple-dark, tertiary),
);

$_primary-purple-dark: map.merge(map.get($_palettes-purple-dark, primary), $_rest-purple-dark);
$_tertiary-purple-dark: map.merge(map.get($_palettes-purple-dark, tertiary), $_rest-purple-dark);

$m3-purple-dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary-purple-dark,
    tertiary: $_tertiary-purple-dark,
    use-system-variables: true,
  ),
  typography: (
    brand-family: 'Segoe UI',
    bold-weight: 900,
    use-system-variables: true,
  ),
  density: (
    scale: 0
  )
));

.purple-dark-theme {
  --sys-color-scheme: dark;
  --sys-primary: rgb(217 186 250);
  --sys-surface-tint: rgb(217 186 250);
  --sys-on-primary: rgb(61 36 89);
  --sys-primary-container: rgb(84 59 114);
  --sys-on-primary-container: rgb(238 219 255);
  --sys-secondary: rgb(207 193 218);
  --sys-on-secondary: rgb(54 45 64);
  --sys-secondary-container: rgb(77 67 87);
  --sys-on-secondary-container: rgb(236 221 247);
  --sys-tertiary: rgb(242 183 192);
  --sys-on-tertiary: rgb(75 37 44);
  --sys-tertiary-container: rgb(101 59 66);
  --sys-on-tertiary-container: rgb(255 217 222);
  --sys-error: rgb(255 180 171);
  --sys-on-error: rgb(105 0 5);
  --sys-error-container: rgb(147 0 10);
  --sys-on-error-container: rgb(255 218 214);
  --sys-background: rgb(21 18 24);
  --sys-on-background: rgb(232 224 232);
  --sys-surface: rgb(21 18 24);
  --sys-on-surface: rgb(232 224 232);
  --sys-surface-variant: rgb(74 69 78);
  --sys-on-surface-variant: rgb(204 196 207);
  --sys-outline: rgb(149 142 152);
  --sys-outline-variant: rgb(74 69 78);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(232 224 232);
  --sys-inverse-on-surface: rgb(51 47 53);
  --sys-inverse-primary: rgb(109 83 139);
  --sys-primary-fixed: rgb(238 219 255);
  --sys-on-primary-fixed: rgb(39 13 67);
  --sys-primary-fixed-dim: rgb(217 186 250);
  --sys-on-primary-fixed-variant: rgb(84 59 114);
  --sys-secondary-fixed: rgb(236 221 247);
  --sys-on-secondary-fixed: rgb(32 24 42);
  --sys-secondary-fixed-dim: rgb(207 193 218);
  --sys-on-secondary-fixed-variant: rgb(77 67 87);
  --sys-tertiary-fixed: rgb(255 217 222);
  --sys-on-tertiary-fixed: rgb(50 16 24);
  --sys-tertiary-fixed-dim: rgb(242 183 192);
  --sys-on-tertiary-fixed-variant: rgb(101 59 66);
  --sys-surface-dim: rgb(21 18 24);
  --sys-surface-bright: rgb(60 56 62);
  --sys-surface-container-lowest: rgb(16 13 18);
  --sys-surface-container-low: rgb(29 26 32);
  --sys-surface-container: rgb(34 30 36);
  --sys-surface-container-high: rgb(44 41 47);
  --sys-surface-container-highest: rgb(55 51 57);

  --dq-neutral-variant: rgb(38, 43, 36);
  --dq-inactive-contrast: rgb(44, 50, 42);
  --dq-bright-contrast: rgb(54 58 52);
  --dq-box-shadow-color: rgba(255, 255, 255, 0.2);

  --dq-app-color-background: #1E1E1E; /* Dark grey */
  --dq-app-color-warning: #4A4530; /* Muted yellow */
  --dq-app-color-surface: #2E2A27; /* Warm brown */
  --dq-app-color-error: #4A2A2A; /* Muted red */
  --dq-app-color-success: #29432A; /* Dark green */
  --dq-app-color-accent: #334D33; /* Brighter green */

}
