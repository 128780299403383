@use "@angular/material" as mat;

button.mdc-button,
a.mdc-button {

  &.text-nowrap {

    span,
    span.mdc-button__label,
    .mdc-button__label {
      min-width: fit-content;
    }
  }

  mat-icon,
  mat-icon.mat-icon,
  .mat-icon {
    line-height: 1;
    flex-shrink: 0;
  }


  span,
  span.mdc-button__label,
  .mdc-button__label {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  &.mat-warn,
  &.mat-error {
    --sys-primary: var(--sys-error);
    --mdc-text-button-label-text-color: var(--sys-primary);
    --mat-text-button-state-layer-color: var(--sys-primary);
    --mat-text-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, rgba(255, 255, 255, 0.2));
  }
}

mat-button-toggle-group {
  &.mat-button-toggle-vertical {
    border-radius: 4px !important;
  }
}
