@use "@angular/material" as mat;
@import "@angular/material";

@mixin dq-tenant-dialog-component-theme($theme) {

  .sub-container {
    color: var(--sys-secondary);
  }

  .tenant-dialog-inventory-booking-component {
    background-color: #f9f9f9;
  }

  .tenant-dialog-component {
    background-color: #f9f9f9;

    .top-navigation-btn-active {
      background-color: var(--dq-neutral-variant);
      color: var(--sys-on-surface);
    }

    .mat-tab-label-active {
      color: var(--sys-primary);
    }

    .refresh-button:hover {
      text-shadow: 0 0 5px #fff, 0 0 10px var(--sys-primary),
        0 0 20px var(--sys-primary), 0 0 30px var(--sys-primary),
        0 0 40px var(--sys-primary), 0 0 50px var(--sys-primary),
        0 0 60px var(--sys-primary);
    }
  }

  .tenant-dialog-user-general-component {
    .contact-info {
      color: #f9f9f9;
    }

    .tag-div:hover,
    .building-div:hover,
    .preference-div:hover,
    .rep-div:hover {
      background: var(--sys-surface-container-high)!important;
      color: var(--sys-on-surface)!important;
    }
  }

  .tenant-dialog-rep-component {
    .search-icon {
      color: #f9f9f9;
    }
  }

  .tenant-dialog-tag-component {
    .search-icon {
      color: #f9f9f9;
    }
  }

  .dq-tenant-dialog-user-alerts-component {
    .listing-alert-tag {
      background-color: var(--sys-primary);
    }
  }

  .tenant-dialog-task-component {
    .task-done-item {
      background-color: var(--sys-primary);
    }
  }

  .tenant-dialog-inventory-component {
    .filter-div {
      background-color: var(--dq-neutral-variant);
    }
  }

  .tenant-dialog-user-general-component {
    .contact-info-detail-label {
      border: 1px solid var(--sys-primary);
      margin-right: 15px;
    }

    .contact-info-detail-label:hover {
      background-color: var(--sys-surface);
    }

    .basic-info {
      background-color: var(--sys-surface);
    }
  }

  .tenant-dialog-quick-text-message-component {
    .desktop-container {
      background-color: var(--sys-secondary);
    }

    .template-div {
      background-color: var(--sys-surface-container-high);
    }

    .template-div:hover {
      background-color: var(--sys-surface-container-high);
    }
  }

  .tenant-dialog-email-component {
    .desktop-container-email {
      background-color: var(--sys-secondary);
    }

    .template-div {
      background-color: var(--sys-surface-container-high);
    }

    .template-div:hover {
      background-color: var(--sys-surface-container-high);
    }
  }
}