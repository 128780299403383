@use '@angular/material' as mat;

$toolbar-breakpoint: 600px;

$fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts';

$link-hover-decoration: none;
$label-margin-bottom: 0;

$grid-breakpoints: (
  xs: 0,
  xxsm: 180px,
  xsm: 320px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);

//descriptive names
//http://chir.ag/projects/name-that-color/
$silver-tree: #63c086;
$cerulean: #00aeef;
$yellow-orange: #fbb040;
$bouquet: #b77bb5;
$pomegranate: #ef4136;

/*USAGE COLOURS*/
$usage-active: $silver-tree;
$usage-medium: $yellow-orange;
$usage-low: $pomegranate;

// MOBILE VARIABLES
$min-mobile-width: 320px;
$max-mobile-width: 650px;

//Condo Dork
$dashq-orange: #FFA987;
$dashq-blue: #80D7DB;
$dashq-yellow: #F7EE6C;
$dashq-green: #13D77A;
$dashq-black: #101A28;

//Grey was changed to a darker shade from #969696 for contrast readability;
$dashq-grey: #444;

$dashq-secondary: $dashq-green;
$dashq-accent: $dashq-orange;
// $dashq-warning:
$dashq-font-color: $dashq-grey;

$container-radius: 25px;
$text-radius: 0.5ch;
