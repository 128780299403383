@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$_palettes-snow-storm: (
  primary: (
    0: #000000,
    10: #001C37,
    20: #033258,
    25: #153D64,
    30: #234970,
    35: #30547D,
    40: #3D608A,
    50: #5679A4,
    60: #7093BF,
    70: #8BAEDB,
    80: #A6C9F8,
    90: #D2E4FF,
    95: #EAF1FF,
    98: #F8F9FF,
    99: #FDFCFF,
    100: #FFFFFF
  ),
  secondary: (
    0: #000000,
    10: #161C24,
    20: #2B3139,
    25: #353C44,
    30: #414750,
    35: #4C535C,
    40: #585F68,
    50: #717781,
    60: #8B919B,
    70: #A5ACB6,
    80: #C1C7D2,
    90: #DDE3EE,
    95: #EBF1FC,
    98: #F8F9FF,
    99: #FDFCFF,
    100: #FFFFFF
  ),
  tertiary: (
    0: #000000,
    10: #181C20,
    20: #2D3135,
    25: #383C40,
    30: #43474B,
    35: #4F5357,
    40: #5B5F63,
    50: #74777C,
    60: #8D9196,
    70: #A8ABB0,
    80: #C3C7CC,
    90: #E0E3E8,
    95: #EEF1F6,
    98: #F7F9FE,
    99: #FCFCFF,
    100: #FFFFFF
  ),
  neutral: (
    0: #000000,
    10: #191c18,
    20: #2e312d,
    25: #393c38,
    30: #454843,
    35: #50534e,
    40: #5c5f5a,
    50: #8f918c,
    60: #8f918c,
    70: #aaaca6,
    80: #c5c7c1,
    90: #e1e3dd,
    95: #f0f1eb,
    98: #f9faf3,
    99: #fbfdf6,
    100:#FFFFFF
  ),
  neutral-variant: (
    0: #000000,
    10: #171d16,
    20: #2b322a,
    25: #363d35,
    30: #414940,
    35: #4d544b,
    40: #596057,
    50: #72796f,
    60: #8b9388,
    70: #a6ada2,
    80: #c1c9bd,
    90: #dde5d8,
    95: #ecf3e6,
    98: #f4fcef,
    99: #f7fff2,
    100: #FFFFFF
  ),
  error: (
    0: #000000,
    10: #3f0017,
    20: #66002a,
    25: #7a0034,
    30: #90003e,
    35: #a50049,
    40: #bb0054,
    50: #e3216b,
    60: #ff4c85,
    70: #ff85a3,
    80: #ffb1c1,
    90: #ffd9df,
    95: #ffecee,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest-snow-storm: (
  secondary: map.get($_palettes-snow-storm, secondary),
  neutral: map.get($_palettes-snow-storm, neutral),
  neutral-variant: map.get($_palettes-snow-storm, neutral-variant),
  error: map.get($_palettes-snow-storm, tertiary),
);

$_primary-snow-storm: map.merge(map.get($_palettes-snow-storm, primary), $_rest-snow-storm);
$_tertiary-snow-storm: map.merge(map.get($_palettes-snow-storm, tertiary), $_rest-snow-storm);

$m3-snow-storm-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary-snow-storm,
    tertiary: $_tertiary-snow-storm,
    use-system-variables: true,
  ),
  typography: (
    brand-family: 'Segoe UI',
    bold-weight: 900,
    use-system-variables: true,
  ),
  density: (
    scale: 0
  )
));

.snow-storm-theme {
  --sys-color-scheme: light;
  --sys-primary: rgb(45 82 122);
  --sys-surface-tint: rgb(61 96 138);
  --sys-on-primary: rgb(255 255 255);
  --sys-primary-container: rgb(84 119 161);
  --sys-on-primary-container: rgb(255 255 255);
  --sys-secondary: rgb(88 95 104);
  --sys-on-secondary: rgb(255 255 255);
  --sys-secondary-container: rgb(221 227 238);
  --sys-on-secondary-container: rgb(66 73 81);
  --sys-tertiary: rgb(91 95 99);
  --sys-on-tertiary: rgb(255 255 255);
  --sys-tertiary-container: rgb(238 241 246);
  --sys-on-tertiary-container: rgb(77 81 85);
  --sys-error: rgb(186 26 26);
  --sys-on-error: rgb(255 255 255);
  --sys-error-container: rgb(255 218 214);
  --sys-on-error-container: rgb(65 0 2);
  --sys-background: rgb(250 249 253);
  --sys-on-background: rgb(26 28 30);
  --sys-surface: rgb(255 255 255);
  --sys-on-surface: rgb(26 28 30);
  --sys-surface-variant: rgb(223 226 235);
  --sys-on-surface-variant: rgb(67 71 78);
  --sys-outline: rgb(115 119 127);
  --sys-outline-variant: rgb(195 198 207);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(47 48 51);
  --sys-inverse-on-surface: rgb(241 240 244);
  --sys-inverse-primary: rgb(166 201 248);
  --sys-primary-fixed: rgb(210 228 255);
  --sys-on-primary-fixed: rgb(0 28 55);
  --sys-primary-fixed-dim: rgb(166 201 248);
  --sys-on-primary-fixed-variant: rgb(35 73 112);
  --sys-secondary-fixed: rgb(221 227 238);
  --sys-on-secondary-fixed: rgb(22 28 36);
  --sys-secondary-fixed-dim: rgb(193 199 210);
  --sys-on-secondary-fixed-variant: rgb(65 71 80);
  --sys-tertiary-fixed: rgb(224 227 232);
  --sys-on-tertiary-fixed: rgb(24 28 32);
  --sys-tertiary-fixed-dim: rgb(195 199 204);
  --sys-on-tertiary-fixed-variant: rgb(67 71 75);
  --sys-surface-dim: rgb(218 218 221);
  --sys-surface-bright: rgb(250 249 253);
  --sys-surface-container-lowest: rgb(255 255 255);
  --sys-surface-container-low: rgb(244 243 247);
  --sys-surface-container: rgb(238 237 241);
  --sys-surface-container-high: rgb(232 232 235);
  --sys-surface-container-highest: rgb(226 226 230);

  --dq-neutral-variant: rgb(252, 252, 252);
  --dq-inactive-contrast: rgb(235, 235, 235);
  --dq-bright-contrast: rgb(255, 255, 255);
  --dq-box-shadow-color: rgba(0, 0, 0, 0.08);

  --dq-app-color-background: #F8F8F8; /* Dark grey */
  --dq-app-color-warning: #FFFBDD; /* Muted yellow */
  --dq-app-color-surface: #FDF6F2; /* Warm brown */
  --dq-app-color-error: #FFE6E6; /* Muted red */
  --dq-app-color-success: #F3FDF2; /* Dark green */
  --dq-app-color-accent: #ECFFEB; /* Brighter green */

}
