@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-manual-slider-component-theme($theme) {
  
  
  
  
  

  .dq-manual-slider-component {
    .ngx-slider-pointer {
      background-color: var(--sys-primary);
    }

    .ngx-slider-pointer.ngx-slider-active::after {
      background-color: var(--sys-primary);
    }

    .ngx-slider-selection {
      background-color: var(--sys-primary);
    }

    .ngx-slider[disabled] .ngx-slider-pointer,
    .ngx-slider[disabled] .ngx-slider-selection,
    .ngx-slider[disabled] .ngx-slider-bar {
      background-color: var(--dq-neutral-variant);
    }

    .ngx-slider-model-value,
    .ngx-slider-model-high,
    .ngx-slider-combined {
      color: var(--sys-secondary);
    }
  }
}
