@use "@angular/material" as mat;
@import '@angular/material';

@mixin dq-app-component-theme($theme) {

  .app-component {
    .call-center-div {
      background-color: var(--sys-surface);
    }
  }

  .mat-sidenav-content {
    background-color: var(--sys-surface-bright);
  }

  .sidenav-expansion-panel,
  .mat-expansion-panel-body {
    padding: 0 !important;
    border-radius: 0 !important;

    .child-span {
      padding-left: 32px;
    }
  }

  .mat-expansion-panel-header {
    padding: 0 16px !important;
    border-radius: 0 !important;
  }

  .mat-card-header {
    background-color: var(--sys-secondary);
  }

  .application-content {
    .mobile-footer-menu-actived {
      color: var(--sys-primary);
    }
  }

  * {
    caret-color: var(--sys-on-surface-variant) !important;
  }

  .link-tip {
    background-color: var(--sys-primary);
  }

  button.mat-flat-button:hover {
    filter: brightness(90%);
  }

  mat-sidenav-container {

    background-color: var(--sys-surface);

    mat-toolbar {

      button,
      a {
        &.active {
          color: var(--sys-primary);
          background-color: var(--sys-primary);
        }
      }

      .link {
        color: var(--sys-primary);
      }
    }

    &>mat-sidenav {
      background-color: var(--sys-surface);
    }

    mat-sidenav,
    mat-nav-list {
      .mat-list-item {
        &.active {
          color: var(--sys-primary);
        }
      }
    }

    .footer {

      color: var(--sys-on-primary);
      background-color: var(--sys-primary);

      .links,
      .signature {
        a {
          color: var(--sys-primary);

          &:hover {
            color: var(--sys-secondary);
          }
        }
      }
    }
  }

  .mat-tab-nav-bar {
    background-color: var(--sys-surface);
  }

  mat-error {
    mat-card {
      color: var(--sys-error) !important;
    }

    &.important {
      mat-icon {

        &::after,
        &::before {
          border-color: var(--sys-error) !important;
        }
      }
    }
  }

  .error-notification-overlay {
    color: var(--sys-error);
    background-color: var(--sys-error);
  }

  .info-notification-overlay {
    color: var(--sys-secondary);
    background-color: var(--sys-secondary);
  }

  .success-notification-overlay {
    color: var(--sys-on-primary);
    background-color: var(--sys-primary);
  }

  .on-going-call-handler-overlay {
    z-index: 1001;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) !important;
    background: unset;
    height: 0;
    width: 0;
    padding: 0;
    min-height: unset;
    box-shadow: unset;
    margin: 0;
  }

  .no-padding-dialog-container {}

  .warning-notification-overlay {
    color: var(--sys-error);
    background-color: var(--sys-error);
  }

  .mat-expansion-panel-body .mat-list-item.active {
    opacity: 0.8;
    background-color: var(--sys-primary);
  }

  div#image-editor {
    .drag {
      border: 2px dashed var(--sys-secondary);
    }
  }

  .highlighted {
    position: relative;
    z-index: 1;
    width: fit-content;
    height: fit-content;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      padding-top: 0.36rem;
      border-radius: 0.25rem;
      width: 100%;
      z-index: -1;
      background-color: var(--sys-secondary);
      // opacity: 0.32;
    }
  }

  #wave-gradient {
    &>stop:nth-child(1) {
      stop-color: var(--sys-primary);
    }

    &>stop:nth-child(2) {
      stop-color: var(--sys-primary);
    }
  }

  .swiper-button-prev {
    &::after {
      text-shadow: 0 1px 0 var(--sys-primary),
        1px 0 0 var(--sys-primary),
        1px 2px 1px var(--sys-primary),
        2px 1px 1px var(--sys-primary),
        2px 3px 2px var(--sys-primary),
        3px 2px 1px var(--sys-primary),
        3px 4px 1px var(--sys-primary);
    }
  }

  .swiper-button-next {
    &::after {
      text-shadow: 0 1px 0 var(--sys-primary),
        -1px 0 0px var(--sys-primary),
        -1px 2px 1px var(--sys-primary),
        -2px 1px 1px var(--sys-primary),
        -2px 3px 2px var(--sys-primary),
        -3px 2px 1px var(--sys-primary),
        -3px 4px 1px var(--sys-primary);
    }
  }

  .connection-active {
    background-color: var(--dq-neutral-variant);
  }
}
