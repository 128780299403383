@use '@angular/material' as mat;
@import "@angular/material";

@mixin dq-building-component-theme($theme) {

  .building-editor-messaging-component {
    .message-selected {
      border: 2px solid var(--sys-primary);
    }
  }

  .building-edit-building-property-component {
    .filter-div {
      background-color: var(--sys-surface);
    }
  }

  .building-floor-plan-inventory-assign-component {
    .filter-div {
      background-color: var(--sys-surface);
    }
  }

  .building-edit-building-property-inventory-component {
    .filter-div {
      background-color: var(--sys-surface);
    }
  }

  .building-edit-building-property-rental-item-component {
    .filter-div {
      background-color: var(--sys-surface) !important
    }
  }

  .building-editor-messaging-component {
    .warn-info {
      background-color: var(--sys-secondary) !important;
    }
  }
}