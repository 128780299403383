* {
  &:active,
  :focus {
    outline: none !important;
  }
}

code {
  white-space: nowrap;
  border-radius: 10px;
  padding: 0 8px 1px 8px;
  font-family: "Roboto-Mono", monospace;
}


p {
  a {
    border-bottom: 3px solid;
  }
}

body {
  overflow: hidden;
  font-size: 16px;
}


