@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-empty-error-component-theme($theme) {
  
  
  
  
  

  #wave-gradient {
    & > stop:nth-child(1) {
      stop-color: var(--sys-primary);
    }

    & > stop:nth-child(2) {
      stop-color: var(--sys-primary);
    }

    &.warn {
      & > stop:nth-child(1) {
        stop-color: var(--sys-error);
      }

      & > stop:nth-child(2) {
        stop-color: var(--sys-error);
      }
    }
  }

  div.message {
    &,
    a {
      color: var(--sys-primary);
    }

    &.warn {
      &,
      a {
        color: var(--sys-error);
      }
    }
  }
}
