.mat-menu-panel {
  max-width: unset !important;
}
.mat-menu-content {
  fa-icon {
    position: relative;
    top: 2px;
    left: 4px;
  }
}
