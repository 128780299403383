@import "@angular/material";

@mixin dq-sign-in-component-theme($theme) {
    .sign-in-custom-btn {
        background-color: #6ed47f!important;
        color: #215026!important
    }

    .sign-in-custom-spinner {
        circle {
            stroke: #215026!important;
          }
    }
}
