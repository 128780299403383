@use "@angular/material" as mat;
@import "@angular/material";

@mixin dq-notification-component-theme($theme) {
    
    
    
    
    

    .notification-component {
        .notification-item-div :hover {
            background-color: var(--dq-neutral-variant);
        }
    }
}
