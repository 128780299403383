@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-nav-card-component-theme($theme) {
  .dq-nav-card-component {
    a.card-link {
      + .mat-card-content {
        &::after {
          background-color: var(--sys-primary) !important;
        }
      }
    }
  }
}
