@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-lead-manager-component-theme($theme) {

  .nav-container {
    background-color: var(--sys-surface);
  }

  .list-name-div :hover {
    color: var(--sys-primary);
  }

  .drop-down-item :hover {
    color: var(--sys-secondary);
  }

  .follow-up-booss-lead-filter-building-component {
    .building-div-wrapper :hover {
      background: var(--sys-primary);
      color: var(--sys-secondary);
    }

    .tag-div:hover {
      background: var(--sys-surface-container-high)!important;
      color: var(--sys-on-surface)!important;
    }
  }

  .lead-manager-leads-component {

    td.name-col :hover {
      color: var(--sys-secondary);
    }

    .search-icon {
      position: absolute;
      left: 10px;
      color: var(--sys-secondary);
    }
  }
  .lead-manager-tags-component {
    .tag-div:hover {
      background: var(--sys-surface-container-high)!important;
      color: var(--sys-on-surface)!important;
    }
  }
}
