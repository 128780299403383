@use "@angular/material" as mat;
@import "@angular/material";

@mixin dq-service-request-manager-component-theme($theme) {
  
  
  
  
  

  .service-request-manager-service-requests-component {
    .service-row:hover {
      background-color: var(--dq-neutral-variant);
    }
    .service-closed {
      background-color: var(--dq-neutral-variant);
    }
  }
}
