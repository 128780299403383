@use '@angular/material' as mat;
@import "@angular/material";

@mixin dq-timeline-component-theme($theme) {
  
  
  
  

  .timeline-email-component {
    .more-less-toggle {
      color: var(--sys-surface-container-high);
    }
  }

  .timeline-webform-component {
    .metadata {
      background-color: #f9f9f9;
    }
  }
}

