@use "@angular/material" as mat;
@import "@angular/material";

@mixin dq-unified-inbox-component-theme($theme) {


  .unified-inbox-quick-text-message-component {
    .send-icon {
      svg {
        fill: var(--sys-primary);
      }

      svg:hover {
        fill: var(--sys-primary);
      }
    }
  }

  .unified-inbox-outbound-message {
    .outboud-msg-container {
      background-color: var(--sys-surface-container-high);
    }
  }

  .unified-inbox-voice-message {
    .voice-msg-container {
      background-color: var(--sys-surface-container-high);
    }
  }

  .unified-inbox-main-panel-component {

    background-color: var(--sys-surface);

    .activate {
      background-color: var(--sys-surface-container-high);
    }

    .navigation-btn:hover {
      background-color: var(--sys-surface-container-high);
    }
  }

  .unified-inbox-lead-info-component {

    .tag-div:hover,
    .building-div:hover,
    .preference-div:hover,
    .rep-div:hover {
      background: var(--sys-surface-container-high)!important;
      color: var(--sys-on-surface)!important;
    }
  }

  .unified-inbox-lead-base-info-component {
    .contact-info-detail-label:hover {
      background-color: var(--sys-primary);
    }
  }

  .unified-inbox-conversation-component {

    background-color: var(--sys-surface);

    .conversation-container:hover {
      background-color: var(--sys-surface-container-high);
    }

    .conversation-unread {
      border-left: 5px solid var(--sys-surface-container-high) !important;
    }

    .conversation-overdue {
      border-left: 5px solid var(--sys-error) !important;
    }

    .conversation-selected {
      background-color: var(--sys-surface-container-hightest);
    }
  }

  .unified-inbox-text-message-component,
  .unified-inbox-quick-text-message-component,
  .unified-inbox-email-component {
    .send-icon {
      svg {
        fill: var(--sys-primary);
      }

      svg:hover {
        fill: var(--sys-primary);
      }
    }

    .template-div {
      background-color: var(--sys-surface-container-high);
    }

    .template-div:hover {
      background-color: var(--sys-surface-container-highest);
    }
  }

  .unified-inbox-lead-base-info-component {

    background-color: var(--sys-surface);

    .contact-info-detail-label {
      margin-right: 15px;
    }

    .tag-div:hover,
    .building-div:hover,
    .preference-div:hover,
    .rep-div:hover {
      background: var(--sys-surface-container-high)!important;
      color: var(--sys-on-surface)!important;
    }

  }
}