@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$_palettes-purple-light: (
  primary: (
    0: #000000,
    10: #2A0053,
    20: #431574,
    25: #4F2380,
    30: #5B308C,
    35: #673D99,
    40: #7449A6,
    50: #8E63C1,
    60: #A97CDD,
    70: #C497FA,
    80: #DAB9FF,
    90: #EEDBFF,
    95: #F9EDFF,
    98: #FFF7FF,
    99: #FFFBFF,
    100: #FFFFFF
  ),
  secondary: (
    0: #000000,
    10: #21172B,
    20: #362C41,
    25: #41374D,
    30: #4D4259,
    35: #594E65,
    40: #655A71,
    50: #7F728B,
    60: #998CA5,
    70: #B4A6C0,
    80: #D0C1DC,
    90: #EDDDF9,
    95: #F9EDFF,
    98: #FFF7FF,
    99: #FFFBFF,
    100: #FFFFFF
  ),
  tertiary: (
    0: #000000,
    10: #340F17,
    20: #4D232C,
    25: #5A2E36,
    30: #673942,
    35: #75454D,
    40: #825059,
    50: #9E6871,
    60: #BB818A,
    70: #D89BA4,
    80: #F6B6BF,
    90: #FFD9DE,
    95: #FFECEE,
    98: #FFF8F7,
    99: #FFFBFF,
    100: #FFFFFF
  ),
  neutral: (
    0: #000000,
    10: #1D1B1E,
    20: #322F33,
    25: #3D3A3E,
    30: #49464A,
    35: #555155,
    40: #615D61,
    50: #7A767A,
    60: #948F94,
    70: #AFAAAE,
    80: #CAC5C9,
    90: #E7E1E5,
    95: #F5EFF4,
    98: #FEF7FC,
    99: #FFFBFF,
    100: #FFFFFF
  ),
  neutral-variant: (
    0: #000000,
    10: #1E1A22,
    20: #332F37,
    25: #3E3A42,
    30: #4A454E,
    35: #56505A,
    40: #625C66,
    50: #7B757F,
    60: #958E98,
    70: #B0A9B3,
    80: #CCC4CF,
    90: #E8E0EB,
    95: #F7EEF9,
    98: #FFF7FF,
    99: #FFFBFF,
    100: #FFFFFF
  ),
  error: (
    0: #000000,
    10: #3f0017,
    20: #66002a,
    25: #7a0034,
    30: #90003e,
    35: #a50049,
    40: #bb0054,
    50: #e3216b,
    60: #ff4c85,
    70: #ff85a3,
    80: #ffb1c1,
    90: #ffd9df,
    95: #ffecee,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest-purple-light: (
  secondary: map.get($_palettes-purple-light, secondary),
  neutral: map.get($_palettes-purple-light, neutral),
  neutral-variant: map.get($_palettes-purple-light, neutral-variant),
  error: map.get($_palettes-purple-light, tertiary),
);

$_primary-purple-light: map.merge(map.get($_palettes-purple-light, primary), $_rest-purple-light);
$_tertiary-purple-light: map.merge(map.get($_palettes-purple-light, tertiary), $_rest-purple-light);

$m3-purple-light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary-purple-light,
    tertiary: $_tertiary-purple-light,
    use-system-variables: true,
  ),
  typography: (
    brand-family: 'Segoe UI',
    bold-weight: 900,
    use-system-variables: true,
  ),
  density: (
    scale: 0
  )
));

.purple-light-theme {
  --sys-color-scheme: light;
  --sys-primary: rgb(109 83 139);
  --sys-surface-tint: rgb(109 83 139);
  --sys-on-primary: rgb(255 255 255);
  --sys-primary-container: rgb(238 219 255);
  --sys-on-primary-container: rgb(39 13 67);
  --sys-secondary: rgb(101 90 111);
  --sys-on-secondary: rgb(255 255 255);
  --sys-secondary-container: rgb(236 221 247);
  --sys-on-secondary-container: rgb(32 24 42);
  --sys-tertiary: rgb(128 81 89);
  --sys-on-tertiary: rgb(255 255 255);
  --sys-tertiary-container: rgb(255 217 222);
  --sys-on-tertiary-container: rgb(50 16 24);
  --sys-error: rgb(186 26 26);
  --sys-on-error: rgb(255 255 255);
  --sys-error-container: rgb(255 218 214);
  --sys-on-error-container: rgb(65 0 2);
  --sys-background: rgb(255 247 255);
  --sys-on-background: rgb(29 26 32);
  --sys-surface: rgb(255 255 255);
  --sys-on-surface: rgb(29 26 32);
  --sys-surface-variant: rgb(232 224 235);
  --sys-on-surface-variant: rgb(74 69 78);
  --sys-outline: rgb(123 117 127);
  --sys-outline-variant: rgb(204 196 207);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(51 47 53);
  --sys-inverse-on-surface: rgb(246 238 246);
  --sys-inverse-primary: rgb(217 186 250);
  --sys-primary-fixed: rgb(238 219 255);
  --sys-on-primary-fixed: rgb(39 13 67);
  --sys-primary-fixed-dim: rgb(217 186 250);
  --sys-on-primary-fixed-variant: rgb(84 59 114);
  --sys-secondary-fixed: rgb(236 221 247);
  --sys-on-secondary-fixed: rgb(32 24 42);
  --sys-secondary-fixed-dim: rgb(207 193 218);
  --sys-on-secondary-fixed-variant: rgb(77 67 87);
  --sys-tertiary-fixed: rgb(255 217 222);
  --sys-on-tertiary-fixed: rgb(50 16 24);
  --sys-tertiary-fixed-dim: rgb(242 183 192);
  --sys-on-tertiary-fixed-variant: rgb(101 59 66);
  --sys-surface-dim: rgb(223 216 224);
  --sys-surface-bright: rgb(255 247 255);
  --sys-surface-container-lowest: rgb(255 255 255);
  --sys-surface-container-low: rgb(249 241 249);
  --sys-surface-container: rgb(243 235 243);
  --sys-surface-container-high: rgb(237 230 238);
  --sys-surface-container-highest: rgb(232 224 232);

  --dq-neutral-variant: rgb(252, 252, 252);
  --dq-inactive-contrast: rgb(235, 235, 235);
  --dq-bright-contrast: rgb(255, 255, 255);
  --dq-box-shadow-color: rgba(0, 0, 0, 0.08);

  --dq-app-color-background: #F8F8F8; /* Dark grey */
  --dq-app-color-warning: #FFFBDD; /* Muted yellow */
  --dq-app-color-surface: #FDF6F2; /* Warm brown */
  --dq-app-color-error: #FFE6E6; /* Muted red */
  --dq-app-color-success: #F3FDF2; /* Dark green */
  --dq-app-color-accent: #ECFFEB; /* Brighter green */

}
