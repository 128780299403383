
mat-icon.mat-icon {
  display: inline-flex !important;
  overflow: unset !important;

  svg {
    fill: inherit;
  }
}

button.mat-mdc-icon-button {
  display: inline-flex !important;
}
