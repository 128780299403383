@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-rental-form-template-component-theme($theme) {
  .rental-form-templates-component {
    .template-item:not(.selected):hover {
      background-color: var(--sys-surface);
    }
  
    .template-item.selected {
      background-color: var(--sys-surface);
    }
  }
}
