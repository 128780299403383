@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-user-profile-component-theme($theme) {
  
  dq-user-profile {
    .nav-responsive {
      border-bottom: 1px solid var(--sys-primary);

      mat-select {
        line-height: 48px;
        margin: 0 20px;
        width: 100%;
      }
    }

    .throught-heart-text {
      color: var(--sys-surface-container-high);

      & + span {
        color: var(--sys-on-surface) !important;
      }
    }

    .highlight-element {
      color: var(--sys-surface-container-high);
      border: solid 0.16rem var(--sys-surface-container-high);
    }

    .client-dialog-user-profile-component, .client-dialog-user-sessions-timeline-item-component {
      em {
        color: var(--sys-on-surface) !important;
      }

      form {

        input, textarea,
        .mat-checkbox-disabled .mat-checkbox-layout {
          color: var(--sys-on-surface) !important;
        }

        label,
        .mat-checkbox-layout {
          color: var(--sys-on-surface) !important;
        }
      }
    }

    .section_icon {
      mat-icon {
        filter: drop-shadow(1px 1px 0 var(--sys-primary)) drop-shadow(-1px -1px 0 var(--sys-primary));
      }
    }

    .nav-responsive-overlay {
      .active {
        color: var(--sys-secondary);
        background-color: var(--sys-secondary);
      }
    }

    .is-selected {
      border: 2px solid var(--sys-secondary);

      &::before {
        border: 4px solid var(--sys-secondary);
      }
    }

    .section {
      &::before {
        background-color: var(--sys-primary);
      }

      a:hover {
        color: var(--sys-secondary) !important;
      }
    }

    svg {
      &.progress {
        circle {
          stroke: var(--sys-secondary) !important;
        }

        &.isWarning {
          circle {
            stroke: var(--sys-error) !important;
          }
        }
      }
    }
  }
}
