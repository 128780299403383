@use '@angular/material' as mat;
@import "@angular/material";

@mixin dq-task-component-theme($theme) {
  .dq-task-manager-component {
    .mat-row:hover {
      background-color: var(--sys-surface);
    }
  
    .task-done-item {
      background-color: var(--sys-surface);
    }
  
    .mat-header-row {
      background-color: var(--sys-surface);
      border-bottom: 1px solid var(--sys-surface-container-high);
    }
  }
}
