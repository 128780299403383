@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-booking-component-theme($theme) {

  .time-slot-item:hover {
    background-color: var(--sys-primary);
    border-color: var(--sys-primary);
    color: var(--sys-on-primary);
    border: 1px solid;
  }

  dq-booking-account-representatives {
    .building-card {
      background-color: var(--sys-surface-bright);
      color: var(--sys-secondary);
    }
  }

  .booking-building-calendars-component {
    .event-div:hover {
      background-color: var(--sys-surface-bright);
    }
  }

  .building-weekly-availability-component {
    .no-time-slots-div {
      color: var(--sys-secondary);
    }
    .event-date-time-div {
      color: var(--text-color, var(--sys-secondary));
    }

    .dq-calendar-component {
      .outer-header {
          background-color: var(--sys-primary);
      }
    }

  }

  .booking-calendar-component {
    .no-time-slots-div {
      border: 2px solid var(--sys-primary);
    }
    .time-slot-item:hover {
      background-color: var(--sys-primary);
      border-color: var(--sys-primary);
      color: var(--sys-on-primary);
    }
  }
  .booking-account-representatives-picker-component {
    .rep-link:hover {
      color: var(--sys-on-secondary);
      background-color: var(--sys-secondary);
     }

    .rep-link {
      color: var(--sys-primary);
      background: var(--sys-surface);
      border-color: var(--sys-secondary);
      border: 1px solid;
    }
  }
}
