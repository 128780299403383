@use "@angular/material" as mat;
@import "@angular/material";

@mixin dq-account-settings-component-theme($theme) {
  dq-account-settings {
    .header {
      .ng-invalid .mat-form-field-flex {
        border: 2px solid var(--sys-error) !important;
      }
    }

    .building-card {
      background-color: var(--sys-surface-bright);
    }

    dq-list-items {
      &>section {
        background-color: var(--sys-surface);

        &.selected-item form header,
        &.selected-item header.primary {
          background-color: var(--sys-surface);
        }

        &.selected-item footer.selected-actions {
          background-color: var(--sys-surface);
        }
      }
    }

    .text-settings-component {
      .text-item-div:hover {
        background-color: var(--dq-neutral-variant);
      }

      .text-select {
        background-color: var(--sys-primary);
      }
    }

    .email-settings-component {
      .email-item-div:hover {
        background-color: var(--dq-neutral-variant);
      }

      .email-select {
        background-color: var(--sys-primary);
      }
    }

    .queue-settings-component {
      .queue-item-div:hover {
        background-color: var(--dq-neutral-variant);
      }

      .queue-select {
        background-color: var(--sys-primary);
      }
    }

    .phone-number-queue-settings-component {
      .phone-number-item-div:hover {
        background-color: var(--dq-neutral-variant);
      }

      .phone-number-select {
        background-color: var(--sys-primary);
      }
    }

    mat-tab-group.mat-accent {

      .mat-tab-label-active,
      .active {
        &:after {
          background-color: var(--sys-secondary);
        }

        &:hover {
          &:after {
            background-color: var(--sys-secondary);
          }
        }
      }
    }

    .voice-recorder-component {
      .overwrite-warning {
        color: var(--sys-error);
      }

      .mat-button.mat-accent {
        color: var(--sys-secondary);
      }

      .mat-button-wrapper {
        mat-spinner:not(.mat-warn) circle {
          stroke: var(--sys-on-surface);
        }
      }

      .start-button {
        .mat-badge-content {
          color: var(--sys-secondary);
        }
      }
    }

    .building-queue-component {
      .building-people-detail:hover {
      }
    }

    .building-card {
    }
  }
}