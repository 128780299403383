@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-big-input-component-theme($theme) {
  dq-big-input {
    .big-input {
      input {
        color: var(--sys-on-surface);
        background-color: var(--sys-surface);
      }

      &.has-focus {
        box-shadow: 0 0 15px 2px rgba(var(--sys-secondary)),
          0 0 15px 2px rgba(var(--sys-secondary)),
          0 0 15px 2px rgba(var(--sys-secondary));
      }
    }
  }
}
