@use '@angular/material' as mat;

@include mat.core();
@import "styles-variables";
@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-grid";

@import "styles-reset";
@import "styles-global";
@import "app/styles/index";


// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//@include mat.all-legacy-component-typographies();
//@include mat.legacy-core();

//@import "./themes/default-theme";
@import "app/styles/themes/m3-light-theme";
@import "app/styles/themes/dark-theme.scss";
@import "app/styles/themes/m3-snow-storm-theme.scss";
@import "app/styles/themes/m3-polar-night-theme.scss";
@import "app/styles/themes/m3-purple-light-theme.scss";
@import "app/styles/themes/m3-purple-dark-theme.scss";
@import "app/styles/themes/m3-cyan-light-theme.scss";
@import "app/styles/themes/m3-cyan-dark-theme.scss";
@import "app/styles/themes/m3-rustic-light-theme.scss";
@import "app/styles/themes/m3-rustic-dark-theme.scss";
//@import "./themes/dark-theme";

@import "styles-reset.scss-theme";
@import "./app/app/app.component.scss-theme";
@import "./app/features/client-dialog/client-dialog/client-dialog.component.scss-theme.scss";
@import "./app/features/tenant-dialog/tenant-dialog/tenant-dialog.component.scss-theme.scss";
@import "./app/features/user-profile/user-profile/user-profile.component.scss-theme.scss";
@import "./app/features/sign-in/sign-in/sign-in.component.scss-theme.scss";
@import "./app/features/dashboard/dashboard/dashboard.component.scss-theme.scss";
@import "./app/features/building/building/building.component.scss-theme.scss";
@import "./app/features/lead-appointment/lead-appointment/lead-appointment.component.scss-theme.scss";
@import "./app/features/booking/booking/booking.component.scss-theme.scss";
@import "./app/features/task/task/task.component.scss-theme.scss";
@import "./app/features/service-request-manager/service-request-manager/service-request-manager.component.scss-theme.scss";
@import "./app/features/notification/notification/notification.component-theme.scss";
@import "./app/features/representative-scheduler/representative-scheduler/representative-scheduler.component.scss-theme.scss";
@import "./app/features/lead-manager/lead-manager/lead-manager.component.scss-theme.scss";
@import "./app/features/lead-queue/lead-queue/lead-queue.component.scss-theme.scss";
@import "./app/features/account-integration/account-integration/account-integration.component.scss-theme.scss";
@import "./app/features/account-settings/account-settings/account-settings.component.scss-theme.scss";
@import "./app/features/dashboard-user/dashboard-user/dashboard-user.component.scss-theme.scss";
@import "./app/features/user-schedule/user-shcedule/user-chedule.component.scss-theme.scss";
@import "./app/features/inventory/inventory/inventory.component.scss-theme.scss";
@import "./app/features/unified-inbox/unified-inbox/unified-inbox.component.scss-theme.scss";
@import "./app/features/merchant-account/merchant-account/merchant-account/merchant-account.component-theme.scss";
@import "./app/features/approval-queue/approval-queue/approval-queue.component-theme.scss";
@import "./app/features/tenant-unified-inbox/tenant-unified-inbox/tenant-unified-inbox.component.scss-theme.scss";
@import "./app/features/com-template/com-template/com-template.component.scss-theme.scss";
@import "./app/features/rental-form-templates/rental-form-templates/rental-form-templates.component.scss-theme.scss";
@import "./app/features/call-settings/call-settings/call-settings.component.scss-theme.scss";
@import "./app/shared/components/timeline/timeline/timeline.component.scss-theme.scss";
@import "./app/shared/components/loading/loading.component.scss-theme.scss";
@import "./app/shared/components/empty-error/empty-error/empty-error.component.scss-theme.scss";
@import "./app/shared/components/nav-card/nav-card.component.scss-theme.scss";
@import "./app/shared/components/manual-slider/manual-slider.component.scss-theme.scss";
@import "./app/shared/components/big-input/big-input/big-input.component.scss-theme";
@import "./app/shared/components/calendar/calendar.component.scss-theme.scss";
@import "./app/shared/components/dialog-pad/dialog-pad.component.scss-theme.scss";
@import "./styles-extra.scss-theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import "../../../node_modules/angular-calendar/css/angular-calendar.css";

@mixin dq-custom-components-theme($theme) {
  @include dq-app-component-theme($theme);
  @include dq-client-dialog-component-theme($theme);
  @include dq-tenant-dialog-component-theme($theme);
  @include dq-styles-reset-theme($theme);
  @include dq-big-input-component-theme($theme);
  @include dq-user-profile-component-theme($theme);
  @include dq-user-schedule-component-theme($theme);
  @include dq-loading-component-theme($theme);
  @include dq-nav-card-component-theme($theme);
  @include dq-empty-error-component-theme($theme);
  @include dq-timeline-component-theme($theme);
  @include dq-manual-slider-component-theme($theme);
  @include dq-dashboard-component-theme($theme);
  @include dq-sign-in-component-theme($theme);
  @include dq-booking-component-theme($theme);
  @include dq-representative-scheduler-component-theme($theme);
  @include dq-lead-manager-component-theme($theme);
  @include dq-lead-queue-component-theme($theme);
  @include dq-building-component-theme($theme);
  @include dq-task-component-theme($theme);
  @include dq-account-integration-component-theme($theme);
  @include dq-account-settings-component-theme($theme);
  @include dq-dashboard-user-component-theme($theme);
  @include dq-calendar-component-theme($theme);
  @include dq-lead-appointment-component-theme($theme);
  @include dq-inventory-component-theme($theme);
  @include dq-unified-inbox-component-theme($theme);
  @include dq-tenant-unified-inbox-component-theme($theme);
  @include dq-notification-component-theme($theme);
  @include dq-service-request-manager-component-theme($theme);
  @include dq-com-template-component-theme($theme);
  @include dq-merchan-account-component-theme($theme);
  @include dq-approval-queue-component-theme($theme);
  @include dq-extra-theme($theme);
  @include dq-material-theme($theme);
  @include dq-dialog-pad-component-theme($theme);
  @include dq-rental-form-template-component-theme($theme);
  @include dq-call-settings-component-theme($theme);
}

.default-theme {
  @include mat.core-theme($m3-light-theme);
  @include mat.strong-focus-indicators-theme($m3-light-theme);
  //@include mat.system-level-colors($m3-light-theme);
  @include mat.system-level-typography($m3-light-theme);
  @include mat.typography-hierarchy($m3-light-theme);
  @include dq-custom-components-theme($m3-light-theme);

  color: var(--sys-on-background);
}

.dark-theme {
  @include mat.core-theme($m3-dark-theme);
  @include mat.strong-focus-indicators-theme($m3-dark-theme);
  //@include mat.system-level-colors($m3-dark-theme);
  @include mat.system-level-typography($m3-dark-theme);
  @include mat.typography-hierarchy($m3-dark-theme);
  @include dq-custom-components-theme($m3-dark-theme);

  color: var(--sys-on-background);
}

.snow-storm-theme {
  @include mat.core-theme($m3-snow-storm-theme);
  @include mat.strong-focus-indicators-theme($m3-snow-storm-theme);
  @include mat.system-level-typography($m3-snow-storm-theme);
  @include mat.typography-hierarchy($m3-snow-storm-theme);
  @include dq-custom-components-theme($m3-snow-storm-theme);

  color: var(--sys-on-background);
}

.polar-night-theme {
  @include mat.core-theme($m3-polar-night-theme);
  @include mat.strong-focus-indicators-theme($m3-polar-night-theme);
  @include mat.system-level-typography($m3-polar-night-theme);
  @include mat.typography-hierarchy($m3-polar-night-theme);
  @include dq-custom-components-theme($m3-polar-night-theme);

  color: var(--sys-on-background);
}

.purple-light-theme {
  @include mat.core-theme($m3-purple-light-theme);
  @include mat.strong-focus-indicators-theme($m3-purple-light-theme);
  @include mat.system-level-typography($m3-purple-light-theme);
  @include mat.typography-hierarchy($m3-purple-light-theme);
  @include dq-custom-components-theme($m3-purple-light-theme);

  color: var(--sys-on-background);
}

.purple-dark-theme {
  @include mat.core-theme($m3-purple-dark-theme);
  @include mat.strong-focus-indicators-theme($m3-purple-dark-theme);
  @include mat.system-level-typography($m3-purple-dark-theme);
  @include mat.typography-hierarchy($m3-purple-dark-theme);
  @include dq-custom-components-theme($m3-purple-dark-theme);

  color: var(--sys-on-background);
}

.cyan-light-theme {
  @include mat.core-theme($m3-cyan-light-theme);
  @include mat.strong-focus-indicators-theme($m3-cyan-light-theme);
  @include mat.system-level-typography($m3-cyan-light-theme);
  @include mat.typography-hierarchy($m3-cyan-light-theme);
  @include dq-custom-components-theme($m3-cyan-light-theme);

  color: var(--sys-on-background);
}

.cyan-dark-theme {
  @include mat.core-theme($m3-cyan-dark-theme);
  @include mat.strong-focus-indicators-theme($m3-cyan-dark-theme);
  @include mat.system-level-typography($m3-cyan-dark-theme);
  @include mat.typography-hierarchy($m3-cyan-dark-theme);
  @include dq-custom-components-theme($m3-cyan-dark-theme);

  color: var(--sys-on-background);
}

.rustic-light-theme {
  @include mat.core-theme($m3-rustic-light-theme);
  @include mat.strong-focus-indicators-theme($m3-rustic-light-theme);
  @include mat.system-level-typography($m3-rustic-light-theme);
  @include mat.typography-hierarchy($m3-rustic-light-theme);
  @include dq-custom-components-theme($m3-rustic-light-theme);

  color: var(--sys-on-background);
}

.rustic-dark-theme {
  @include mat.core-theme($m3-rustic-dark-theme);
  @include mat.strong-focus-indicators-theme($m3-rustic-dark-theme);
  @include mat.system-level-typography($m3-rustic-dark-theme);
  @include mat.typography-hierarchy($m3-rustic-dark-theme);
  @include dq-custom-components-theme($m3-rustic-dark-theme);

  color: var(--sys-on-background);
}

