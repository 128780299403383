mat-nav-list {
  &.rounded-right a {
    padding-inline: 2rem 0.5rem;

    &,
    & div,
    &:after {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

mat-tab-nav-panel {
  & > *:not(router-outlet) {
    display: block;
    max-height: inherit;
    overflow: auto;
    height: 100%;
  }
}
