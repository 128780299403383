@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-styles-reset-theme($theme) {

  *::selection {
    background: var(--sys-outline);
    color: var(--sys-inverse-on-surface);
  }

  a {
    color: var(--sys-secondary);
    &:hover {
      color: var(--sys-primary);
    }

    &:focus {
      color: var(--sys-primary);
    }
  }

  code {
    color: var(--sys-on-secondary);
    background-color: var(--sys-secondary);
  }

  p {
    a {
      color: var(--sys-secondary);

      &:hover {
        color: var(--sys-secondary);
      }
    }
  }
}
