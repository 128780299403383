@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-calendar-component-theme($theme) {
  .dq-calendar-component {

    .special-date {
      background-color: var(--sys-surface-bright);
    }

    .special-date:hover {
      background-color: var(--sys-primary);
    }

    .mat-calendar-body-selected {
      background-color: var(--sys-primary);
    }
  }
}
