@use '@angular/material' as mat;
@import "@angular/material";

@mixin dq-lead-appointment-component-theme($theme) {
  
  .lead-appointment-calendar-component {
    .filter-div {
      background-color: var(--sys-surface);
    }

  }

  .cal-month-view .cal-cell-row .cal-day-cell:hover,
  .cal-week-view .cal-day-headers .cal-header:hover {
    background-color: var(--sys-surface-container-high);
  }

  .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: var(--sys-surface-container-high);
  }

  .cal-month-view .cal-day-badge {
    background-color: var(--sys-tertiary);
  }

  .attended-appointment {
    background-color: var(--sys-surface);
  }

  .icon-attended-inactive:hover {
    color: var(--sys-primary);
  }

  .icon-attended-active {
    color: var(--sys-primary);
  }

  .cal-month-view .cal-day-cell.cal-today {
    background-color: var(--sys-surface-container-high);
  }
}
