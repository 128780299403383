
.mat-mdc-dialog-panel {
  max-width: 100dvw !important;
}
mat-dialog-container {
  .mat-mdc-dialog-surface {
    padding: 0 !important;
    overflow: hidden !important;
  }

  .mat-mdc-dialog-title {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .mat-dialog-content {
    margin: 0 !important;
    padding: 0 !important;
  }
}
