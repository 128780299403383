@use '@angular/material' as mat;
@import "@angular/material";

@mixin dq-call-settings-component-theme($theme) {
  .call-settings-component {
    background-color: var(--sys-surface);
  }

  .power-off-btn {
    background-color: #bb0054;
    color: #ff85a3;
  }
}