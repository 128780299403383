@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$_palettes: (
  primary: (
    0: #000000,
    10: #002106,
    20: #063911,
    25: #14441b,
    30: #215026,
    35: #2d5c30,
    40: #39693b,
    50: #518252,
    60: #6a9d6a,
    70: #84b883,
    80: #9fd49c,
    90: #baf0b6,
    95: #c8ffc4,
    98: #ebffe6,
    99: #f6fff0,
    100: #FFFFFF
  ),
  secondary: (
    0: #000000,
    10: #0e1f0e,
    20: #233522,
    25: #2d402c,
    30: #394b37,
    35: #445742,
    40: #50634e,
    50: #687c65,
    60: #81967e,
    70: #9cb198,
    80: #b7cdb2,
    90: #d2e9cd,
    95: #e1f7db,
    98: #ebffe5,
    99: #f6fff0,
    100: #FFFFFF
  ),
  tertiary: (
    0: #000000,
    10: #331100,
    20: #542100,
    25: #652A00,
    30: #773200,
    35: #893B02,
    40: #99470F,
    50: #B85E27,
    60: #D8773E,
    70: #F89155,
    80: #FFB68F,
    90: #FFDBCA,
    95: #FFEDE6,
    98: #FFF8F6,
    99: #FFFBFF,
    100: #FFFFFF
  ),
  neutral: (
    0: #000000,
    10: #191c18,
    20: #2e312d,
    25: #393c38,
    30: #454843,
    35: #50534e,
    40: #5c5f5a,
    50: #8f918c,
    60: #8f918c,
    70: #aaaca6,
    80: #c5c7c1,
    90: #e1e3dd,
    95: #f0f1eb,
    98: #f9faf3,
    99: #fbfdf6,
    100:#FFFFFF
  ),
  neutral-variant: (
    0: #000000,
    10: #171d16,
    20: #2b322a,
    25: #363d35,
    30: #414940,
    35: #4d544b,
    40: #596057,
    50: #72796f,
    60: #8b9388,
    70: #a6ada2,
    80: #c1c9bd,
    90: #dde5d8,
    95: #ecf3e6,
    98: #f4fcef,
    99: #f7fff2,
    100: #FFFFFF
  ),
  error: (
    0: #000000,
    10: #3f0017,
    20: #66002a,
    25: #7a0034,
    30: #90003e,
    35: #a50049,
    40: #bb0054,
    50: #e3216b,
    60: #ff4c85,
    70: #ff85a3,
    80: #ffb1c1,
    90: #ffd9df,
    95: #ffecee,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);

$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$m3-light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    brand-family: 'Segoe UI',
    bold-weight: 900,
    use-system-variables: true,
  ),
  density: (
    scale: 0
  )
));


.default-theme {
  --sys-color-scheme: light;
  --sys-primary: rgb(57 105 59);
  --sys-surface-tint: rgb(57 105 59);
  --sys-on-primary: rgb(255 255 255);
  --sys-primary-container: rgb(186 240 182);
  --sys-on-primary-container: rgb(0 33 6);
  --sys-secondary: rgb(82 99 80);
  --sys-on-secondary: rgb(255 255 255);
  --sys-secondary-container: rgb(213 232 207);
  --sys-on-secondary-container: rgb(16 31 16);
  --sys-tertiary: rgb(57 101 107);
  --sys-on-tertiary: rgb(255 255 255);
  --sys-tertiary-container: rgb(188 235 241);
  --sys-on-tertiary-container: rgb(0 31 35);
  --sys-error: rgb(186 26 26);
  --sys-on-error: rgb(255 255 255);
  --sys-error-container: rgb(255 218 214);
  --sys-on-error-container: rgb(65 0 2);
  --sys-background: rgb(247 251 242);
  --sys-on-background: rgb(24 29 23);
  --sys-surface: rgb(255 255 255);
  --sys-on-surface: rgb(24 29 23);
  --sys-surface-variant: rgb(222 229 217);
  --sys-on-surface-variant: rgb(66 73 64);
  --sys-outline: rgb(114 121 111);
  --sys-outline-variant: rgb(194 201 189);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(45 50 44);
  --sys-inverse-on-surface: rgb(238 242 233);
  --sys-inverse-primary: rgb(159 212 156);
  --sys-primary-fixed: rgb(186 240 182);
  --sys-on-primary-fixed: rgb(0 33 6);
  --sys-primary-fixed-dim: rgb(159 212 156);
  --sys-on-primary-fixed-variant: rgb(33 80 37);
  --sys-secondary-fixed: rgb(213 232 207);
  --sys-on-secondary-fixed: rgb(16 31 16);
  --sys-secondary-fixed-dim: rgb(185 204 180);
  --sys-on-secondary-fixed-variant: rgb(59 75 57);
  --sys-tertiary-fixed: rgb(188 235 241);
  --sys-on-tertiary-fixed: rgb(0 31 35);
  --sys-tertiary-fixed-dim: rgb(161 206 213);
  --sys-on-tertiary-fixed-variant: rgb(31 77 83);
  --sys-surface-dim: rgb(215 219 211);
  --sys-surface-bright: rgb(247 251 242);
  --sys-surface-container-lowest: rgb(255 255 255);
  --sys-surface-container-low: rgb(241 245 236);
  --sys-surface-container: rgb(255 255 255);
  --sys-surface-container-high: rgb(230 233 225);
  --sys-surface-container-highest: rgb(224 228 219);

  --dq-neutral-variant: rgb(252, 252, 252);
  --dq-inactive-contrast: rgb(235, 235, 235);
  --dq-bright-contrast: rgb(255, 255, 255);
  --dq-box-shadow-color: rgba(0, 0, 0, 0.08);

  --dq-app-color-background: #F8F8F8; /* Dark grey */
  --dq-app-color-warning: #FFFBDD; /* Muted yellow */
  --dq-app-color-surface: #FDF6F2; /* Warm brown */
  --dq-app-color-error: #FFE6E6; /* Muted red */
  --dq-app-color-success: #F3FDF2; /* Dark green */
  --dq-app-color-accent: #ECFFEB; /* Brighter green */
}
