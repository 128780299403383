@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-loading-component-theme($theme) {
  dq-loading {
    .spinner {
      circle {
        stroke: var(--sys-primary) !important;
      }
    }
  }
}
