@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$_palettes-rustic-dark: (
  primary: (
    0: #FFFFFF,
    10: #FFFBFF,
    20: #FFF8F5,
    25: #FFEDE4,
    30: #FFDCC6,
    35: #FFB784,
    40: #F3944B,
    50: #D37A34,
    60: #B4621C,
    70: #944A00,
    80: #824000,
    90: #713700,
    95: #602E00,
    98: #4F2500,
    99: #301400,
    100: #000000
  ),
  secondary: (
    0: #FFFFFF,
    10: #FFFBFF,
    20: #FFF8F5,
    25: #FFEDE4,
    30: #FFDCC6,
    35: #E5BFA7,
    40: #C8A48D,
    50: #AB8A74,
    60: #90715C,
    70: #755845,
    80: #684D3A,
    90: #5B412F,
    95: #4F3625,
    98: #422B1B,
    99: #030303,
    100: #000000
  ),
  tertiary: (
    0: #FFFFFF,
    10: #FFFFD3,
    20: #FCFDC3,
    25: #F3F5BB,
    30: #E4E6AD,
    35: #C8CA93,
    40: #ADAF7A,
    50: #929462,
    60: #787A4B,
    70: #5F6134,
    80: #535529,
    90: #47491F,
    95: #3C3E15,
    98: #30330A,
    99: #1B1D00,
    100: #000000
  ),
  neutral: (
    0: #FFFFFF,
    10: #FFFBFF,
    20: #FFF8F5,
    25: #FBEEE8,
    30: #ECE0DA,
    35: #D0C4BF,
    40: #B4A9A4,
    50: #988F8A,
    60: #7E7570,
    70: #655D58,
    80: #59514C,
    90: #4D4541,
    95: #413A36,
    98: #362F2B,
    99: #201A17,
    100: #000000
  ),
  neutral-variant: (
    0: #FFFFFF,
    10: #FFFBFF,
    20: #FFF8F5,
    25: #FFEDE4,
    30: #F3DED2,
    35: #D6C3B7,
    40: #BAA89D,
    50: #9F8D83,
    60: #84746A,
    70: #6A5B52,
    80: #5E5047,
    90: #52443B,
    95: #463930,
    98: #3A2E26,
    99: #241912,
    100: #000000
  ),
  error: (
    0: #000000,
    10: #3f0017,
    20: #66002a,
    25: #7a0034,
    30: #90003e,
    35: #a50049,
    40: #bb0054,
    50: #e3216b,
    60: #ff4c85,
    70: #ff85a3,
    80: #ffb1c1,
    90: #ffd9df,
    95: #ffecee,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest-rustic-dark: (
  secondary: map.get($_palettes-rustic-dark, secondary),
  neutral: map.get($_palettes-rustic-dark, neutral),
  neutral-variant: map.get($_palettes-rustic-dark, neutral-variant),
  error: map.get($_palettes-rustic-dark, tertiary),
);

$_primary-rustic-dark: map.merge(map.get($_palettes-rustic-dark, primary), $_rest-rustic-dark);
$_tertiary-rustic-dark: map.merge(map.get($_palettes-rustic-dark, tertiary), $_rest-rustic-dark);

$m3-rustic-dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary-rustic-dark,
    tertiary: $_tertiary-rustic-dark,
    use-system-variables: true,
  ),
  typography: (
    brand-family: 'Segoe UI',
    bold-weight: 900,
    use-system-variables: true,
  ),
  density: (
    scale: 0
  )
));

.rustic-dark-theme {
  --sys-color-scheme: dark;
  --sys-primary: rgb(255 183 132);
  --sys-surface-tint: rgb(255 183 132);
  --sys-on-primary: rgb(79 37 0);
  --sys-primary-container: rgb(109 57 12);
  --sys-on-primary-container: rgb(255 220 198);
  --sys-secondary: rgb(228 191 168);
  --sys-on-secondary: rgb(66 43 27);
  --sys-secondary-container: rgb(91 65 47);
  --sys-on-secondary-container: rgb(255 220 198);
  --sys-tertiary: rgb(200 202 148);
  --sys-on-tertiary: rgb(48 51 11);
  --sys-tertiary-container: rgb(71 73 31);
  --sys-on-tertiary-container: rgb(228 230 174);
  --sys-error: rgb(255 180 171);
  --sys-on-error: rgb(105 0 5);
  --sys-error-container: rgb(147 0 10);
  --sys-on-error-container: rgb(255 218 214);
  --sys-background: rgb(25 18 13);
  --sys-on-background: rgb(240 223 214);
  --sys-surface: rgb(25 18 13);
  --sys-on-surface: rgb(240 223 214);
  --sys-surface-variant: rgb(82 68 59);
  --sys-on-surface-variant: rgb(214 195 183);
  --sys-outline: rgb(159 141 131);
  --sys-outline-variant: rgb(82 68 59);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(240 223 214);
  --sys-inverse-on-surface: rgb(56 47 41);
  --sys-inverse-primary: rgb(138 80 34);
  --sys-primary-fixed: rgb(255 220 198);
  --sys-on-primary-fixed: rgb(48 20 0);
  --sys-primary-fixed-dim: rgb(255 183 132);
  --sys-on-primary-fixed-variant: rgb(109 57 12);
  --sys-secondary-fixed: rgb(255 220 198);
  --sys-on-secondary-fixed: rgb(43 23 8);
  --sys-secondary-fixed-dim: rgb(228 191 168);
  --sys-on-secondary-fixed-variant: rgb(91 65 47);
  --sys-tertiary-fixed: rgb(228 230 174);
  --sys-on-tertiary-fixed: rgb(27 29 0);
  --sys-tertiary-fixed-dim: rgb(200 202 148);
  --sys-on-tertiary-fixed-variant: rgb(71 73 31);
  --sys-surface-dim: rgb(25 18 13);
  --sys-surface-bright: rgb(65 55 49);
  --sys-surface-container-lowest: rgb(20 13 8);
  --sys-surface-container-low: rgb(34 26 21);
  --sys-surface-container: rgb(38 30 24);
  --sys-surface-container-high: rgb(49 40 34);
  --sys-surface-container-highest: rgb(60 51 45);

  --dq-neutral-variant: rgb(38, 43, 36);
  --dq-inactive-contrast: rgb(44, 50, 42);
  --dq-bright-contrast: rgb(54 58 52);
  --dq-box-shadow-color: rgba(255, 255, 255, 0.2);

  --dq-app-color-background: #1E1E1E; /* Dark grey */
  --dq-app-color-warning: #4A4530; /* Muted yellow */
  --dq-app-color-surface: #2E2A27; /* Warm brown */
  --dq-app-color-error: #4A2A2A; /* Muted red */
  --dq-app-color-success: #29432A; /* Dark green */
  --dq-app-color-accent: #334D33; /* Brighter green */

}
