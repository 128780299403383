@use "@angular/material" as mat;
@import "@angular/material";

@mixin dq-tenant-unified-inbox-component-theme($theme) {
  
  
  
  
  

  .tenant-unified-inbox-quick-text-message-component {
    .text-container {
      background-color: var(--sys-secondary);
    }

    .send-icon {
      svg {
        fill: var(--sys-primary);
      }

      svg:hover {
        fill: var(--sys-primary);
      }
    }
  }

  .tenant-unified-inbox-outbound-message {
    .outboud-msg-container {
      background-color: var(--sys-secondary);
    }
  }

  .tenant-unified-inbox-voice-message {
    .voice-msg-container {
      background-color: var(--sys-secondary);
    }
  }


  .tenant-unified-inbox-main-panel-component {
    .activate {
      background-color: var(--sys-primary);
    }

    .bottom-navigation-container {
      background-color: var(--sys-surface);
    }

    .top-navigation-container {
      background-color: var(--sys-surface);
    }

    .navigation-btn:hover {
      background-color: var(--sys-primary);
    }

    .tenant-unified-inbox-activities-component {
      .top-communication-container {
        .mat-tab-label-container {
          background-color: var(--sys-surface);
        }
      }
    }
  }

  .tenant-unified-inbox-tenant-info-component {

    .tag-div:hover,
    .building-div:hover,
    .preference-div:hover,
    .rep-div:hover {
      background: var(--sys-surface-container-high)!important;
      color: var(--sys-on-surface)!important;
    }
  }

  .tenant-unified-inbox-tenant-base-info-component {
    .contact-info-detail-label {
      border: 1px solid var(--sys-primary);
    }

    .contact-info-detail-label:hover {
      background-color: var(--sys-primary);
    }

    .basic-info {
      background-color: var(--sys-surface);
    }

    .title-header {
      background-color: var(--sys-surface);
    }
  }

  .tenant-unified-inbox-conversation-component {
    .conversation-read {
    }

    .conversation-unread {
      background-color: var(--sys-error);
    }

    .conversation-overdue {
      background-color: var(--sys-error);
    }

    .conversation-selected {
      border-left: 3px solid !important;
      border-left-color: var(--sys-primary) !important;
      background-color: var(--sys-surface);
    }
  }

  .tenant-unified-inbox-inventory-component {
    .filter-div {
      background-color: var(--sys-surface);
    }
  }

  .tenant-unified-inbox-text-message-component,
  .tenant-unified-inbox-quick-text-message-component,
  .tenant-unified-inbox-email-component {
    .desktop-container-email {
      background-color: var(--sys-secondary);
    }

    .footer-chat {
      background-color: var(--sys-surface);
    }

    .send-icon {
      svg {
        fill: var(--sys-primary);
      }

      svg:hover {
        fill: var(--sys-primary);
      }
    }

    .template-div {
      background-color: var(--sys-surface-container-high);
    }

    .template-div:hover {
      background-color: var(--sys-surface-container-highest);
    }
  }

  .tenant-unified-inbox-note-component {
    .note-container {
      background-color: var(--sys-secondary);
    }
  }

  .tenant-unified-inbox-tenant-base-info-component {
    .contact-info-detail-label {
      border: 1px solid var(--sys-primary);
      margin-right: 15px;
    }

    .contact-info-detail-label:hover {
      background-color: var(--sys-surface);
    }

    .basic-info {
      background-color: var(--sys-surface);
    }

    .contact-info {
      color: #f9f9f9;
    }

    .tag-div:hover,
    .building-div:hover,
    .preference-div:hover,
    .rep-div:hover {
      background: var(--sys-surface-container-high)!important;
      color: var(--sys-on-surface)!important;
    }

    .source-title-div {
      background: var(--sys-surface);
    }

    .booking-btn {
      background-color: var(--sys-secondary);
    }

    .call-btn {
      background-color: var(--sys-primary);
    }
  }
}
