@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$_palettes-cyan-dark: (
  primary: (
    0: #FFFFFF,
    10: #F1FFFE,
    20: #E2FFFE,
    25: #ADFFFE,
    30: #8FF3F2,
    35: #00DDDD,
    40: #00BEBE,
    50: #00A1A1,
    60: #008585,
    70: #006A6A,
    80: #005C5C,
    90: #004F4F,
    95: #004343,
    98: #003737,
    99: #002020,
    100: #000000
  ),
  secondary: (
    0: #FFFFFF,
    10: #F1FFFE,
    20: #E2FFFE,
    25: #D3F8F7,
    30: #C5EAE9,
    35: #A9CDCD,
    40: #8EB2B1,
    50: #749797,
    60: #5B7D7D,
    70: #426464,
    80: #365858,
    90: #2A4C4C,
    95: #1E4141,
    98: #123635,
    99: #002020,
    100: #000000
  ),
  tertiary: (
    0: #FFFFFF,
    10: #FDFCFF,
    20: #F8F9FF,
    25: #EAF1FF,
    30: #D3E4FF,
    35: #ACC8F2,
    40: #91ADD5,
    50: #7693B9,
    60: #5D799E,
    70: #446084,
    80: #385477,
    90: #2B486B,
    95: #1F3D5F,
    98: #123153,
    99: #001C38,
    100: #000000
  ),
  neutral: (
    0: #FFFFFF,
    10: #FAFDFC,
    20: #F7FAF9,
    25: #EFF1F0,
    30: #E0E3E2,
    35: #C4C7C6,
    40: #A9ACAB,
    50: #8E9191,
    60: #747877,
    70: #5B5F5F,
    80: #4F5353,
    90: #444747,
    95: #383C3C,
    98: #2D3131,
    99: #191C1C,
    100: #000000
  ),
  neutral-variant: (
    0: #FFFFFF,
    10: #F4FEFD,
    20: #F1FBFA,
    25: #E9F3F2,
    30: #DAE5E4,
    35: #BEC9C8,
    40: #A3ADAD,
    50: #889392,
    60: #6F7979,
    70: #566060,
    80: #4A5454,
    90: #3F4948,
    95: #343D3D,
    98: #293232,
    99: #141D1D,
    100: #000000
  ),
  error: (
    0: #000000,
    10: #3f0017,
    20: #66002a,
    25: #7a0034,
    30: #90003e,
    35: #a50049,
    40: #bb0054,
    50: #e3216b,
    60: #ff4c85,
    70: #ff85a3,
    80: #ffb1c1,
    90: #ffd9df,
    95: #ffecee,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest-cyan-dark: (
  secondary: map.get($_palettes-cyan-dark, secondary),
  neutral: map.get($_palettes-cyan-dark, neutral),
  neutral-variant: map.get($_palettes-cyan-dark, neutral-variant),
  error: map.get($_palettes-cyan-dark, tertiary),
);

$_primary-cyan-dark: map.merge(map.get($_palettes-cyan-dark, primary), $_rest-cyan-dark);
$_tertiary-cyan-dark: map.merge(map.get($_palettes-cyan-dark, tertiary), $_rest-cyan-dark);

$m3-cyan-dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary-cyan-dark,
    tertiary: $_tertiary-cyan-dark,
    use-system-variables: true,
  ),
  typography: (
    brand-family: 'Segoe UI',
    bold-weight: 900,
    use-system-variables: true,
  ),
  density: (
    scale: 0
  )
));

.cyan-dark-theme {
  --sys-color-scheme: dark;
  --sys-primary: rgb(128 213 212);
  --sys-surface-tint: rgb(128 213 212);
  --sys-on-primary: rgb(0 55 55);
  --sys-primary-container: rgb(0 79 79);
  --sys-on-primary-container: rgb(156 241 240);
  --sys-secondary: rgb(176 204 203);
  --sys-on-secondary: rgb(27 53 52);
  --sys-secondary-container: rgb(50 75 75);
  --sys-on-secondary-container: rgb(204 232 231);
  --sys-tertiary: rgb(179 200 232);
  --sys-on-tertiary: rgb(28 49 75);
  --sys-tertiary-container: rgb(51 72 99);
  --sys-on-tertiary-container: rgb(211 228 255);
  --sys-error: rgb(255 180 171);
  --sys-on-error: rgb(105 0 5);
  --sys-error-container: rgb(147 0 10);
  --sys-on-error-container: rgb(255 218 214);
  --sys-background: rgb(14 21 20);
  --sys-on-background: rgb(221 228 227);
  --sys-surface: rgb(14 21 20);
  --sys-on-surface: rgb(221 228 227);
  --sys-surface-variant: rgb(63 73 72);
  --sys-on-surface-variant: rgb(190 201 200);
  --sys-outline: rgb(136 147 146);
  --sys-outline-variant: rgb(63 73 72);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(221 228 227);
  --sys-inverse-on-surface: rgb(43 50 49);
  --sys-inverse-primary: rgb(0 106 106);
  --sys-primary-fixed: rgb(156 241 240);
  --sys-on-primary-fixed: rgb(0 32 32);
  --sys-primary-fixed-dim: rgb(128 213 212);
  --sys-on-primary-fixed-variant: rgb(0 79 79);
  --sys-secondary-fixed: rgb(204 232 231);
  --sys-on-secondary-fixed: rgb(5 31 31);
  --sys-secondary-fixed-dim: rgb(176 204 203);
  --sys-on-secondary-fixed-variant: rgb(50 75 75);
  --sys-tertiary-fixed: rgb(211 228 255);
  --sys-on-tertiary-fixed: rgb(4 28 53);
  --sys-tertiary-fixed-dim: rgb(179 200 232);
  --sys-on-tertiary-fixed-variant: rgb(51 72 99);
  --sys-surface-dim: rgb(14 21 20);
  --sys-surface-bright: rgb(52 58 58);
  --sys-surface-container-lowest: rgb(9 15 15);
  --sys-surface-container-low: rgb(22 29 29);
  --sys-surface-container: rgb(26 33 33);
  --sys-surface-container-high: rgb(37 43 43);
  --sys-surface-container-highest: rgb(47 54 54);

  --dq-neutral-variant: rgb(38, 43, 36);
  --dq-inactive-contrast: rgb(44, 50, 42);
  --dq-bright-contrast: rgb(54 58 52);
  --dq-box-shadow-color: rgba(255, 255, 255, 0.2);

  --dq-app-color-background: #1E1E1E; /* Dark grey */
  --dq-app-color-warning: #4A4530; /* Muted yellow */
  --dq-app-color-surface: #2E2A27; /* Warm brown */
  --dq-app-color-error: #4A2A2A; /* Muted red */
  --dq-app-color-success: #29432A; /* Dark green */
  --dq-app-color-accent: #334D33; /* Brighter green */

}
