@use "@angular/material" as mat;
@import "@angular/material";

@mixin dq-approval-queue-component-theme($theme) {

  .active-icon {
    color: var(--sys-on-surface) !important;
  }

  .inactive-icon {
    color: var(--dq-inactive-contrast);
  }

  .table-row-applicant:hover {
    background-color: var(--dq-neutral-variant);
    cursor: pointer;
  }

  .name-item:hover {
    color: var(--sys-primary);
  }
}
