@use "@angular/material" as mat;
@import "@angular/material";

@mixin dq-dashboard-user-component-theme($theme) {
  
  
  
  
  

  .dashboard-user-live-data-component {
    .user-item:hover {
      background-color: var(--dq-neutral-variant);
    }

    .online-icon {
      color: var(--sys-on-surface);
    }
  }
}
