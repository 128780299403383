@use '@angular/material' as mat;
@import "@angular/material";

@mixin dq-dashboard-component-theme($theme) {

  .deal-item-box {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid var(--sys-primary);
    background-color: var(--sys-surface);
    border-radius: 8px;
  }

  .appointment-item-box {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid var(--sys-primary);
    background-color: var(--sys-surface);
    border-radius: 8px;
  }

  dq-appointment-pipeline {
    .column-header {
      background-color: var(--sys-surface);
    }
  }

  dq-deal-pipeline {
    .column-header {
      background-color: var(--sys-surface);
    }
  }

  dq-statistics {
    .report-container {
      .top-container {
        background-color: var(--sys-surface);
      }
    }
  }

  .deal-item-box:hover {
    background-color: var(--sys-surface);
  }

  .appointment-item-box:hover {
    background-color: var(--sys-surface);
  }

  .building-editor-messaging-component {
    .message-selected {
      border: 2px solid var(--sys-primary);
    }
  }

  .dq-pipeline-board-component {
    .attended-appointment {
      background-color: var(--sys-surface);
    }

    .icon-attended-inactive:hover {
      color: var(--sys-primary);
    }

    .icon-attended-active {
      color: var(--sys-primary);
    }
  }

  .inventory-building-units-component {
    .coming-icon {
      background-color: var(--sys-primary);
    }
  }
}
