@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$_palettes-rustic-light: (
  primary: (
    0: #000000,
    10: #301400,
    20: #4F2500,
    25: #602E00,
    30: #713700,
    35: #824000,
    40: #944A00,
    50: #B4621C,
    60: #D37A34,
    70: #F3944B,
    80: #FFB784,
    90: #FFDCC6,
    95: #FFEDE4,
    98: #FFF8F5,
    99: #FFFBFF,
    100: #FFFFFF
  ),
  secondary: (
    0: #000000,
    10: #030303,
    20: #422B1B,
    25: #4F3625,
    30: #5B412F,
    35: #684D3A,
    40: #755845,
    50: #90715C,
    60: #AB8A74,
    70: #C8A48D,
    80: #E5BFA7,
    90: #FFDCC6,
    95: #FFEDE4,
    98: #FFF8F5,
    99: #FFFBFF,
    100: #FFFFFF
  ),
  tertiary: (
    0: #000000,
    10: #1B1D00,
    20: #30330A,
    25: #3C3E15,
    30: #47491F,
    35: #535529,
    40: #5F6134,
    50: #787A4B,
    60: #929462,
    70: #ADAF7A,
    80: #C8CA93,
    90: #E4E6AD,
    95: #F3F5BB,
    98: #FCFDC3,
    99: #FFFFD3,
    100: #FFFFFF
  ),
  neutral: (
    0: #000000,
    10: #201A17,
    20: #362F2B,
    25: #413A36,
    30: #4D4541,
    35: #59514C,
    40: #655D58,
    50: #7E7570,
    60: #988F8A,
    70: #B4A9A4,
    80: #D0C4BF,
    90: #ECE0DA,
    95: #FBEEE8,
    98: #FFF8F5,
    99: #FFFBFF,
    100: #FFFFFF
  ),
  neutral-variant: (
    0: #000000,
    10: #241912,
    20: #3A2E26,
    25: #463930,
    30: #52443B,
    35: #5E5047,
    40: #6A5B52,
    50: #84746A,
    60: #9F8D83,
    70: #BAA89D,
    80: #D6C3B7,
    90: #F3DED2,
    95: #FFEDE4,
    98: #FFF8F5,
    99: #FFFBFF,
    100: #FFFFFF
  ),
  error: (
    0: #000000,
    10: #3f0017,
    20: #66002a,
    25: #7a0034,
    30: #90003e,
    35: #a50049,
    40: #bb0054,
    50: #e3216b,
    60: #ff4c85,
    70: #ff85a3,
    80: #ffb1c1,
    90: #ffd9df,
    95: #ffecee,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest-rustic-light: (
  secondary: map.get($_palettes-rustic-light, secondary),
  neutral: map.get($_palettes-rustic-light, neutral),
  neutral-variant: map.get($_palettes-rustic-light, neutral-variant),
  error: map.get($_palettes-rustic-light, tertiary),
);

$_primary-rustic-light: map.merge(map.get($_palettes-rustic-light, primary), $_rest-rustic-light);
$_tertiary-rustic-light: map.merge(map.get($_palettes-rustic-light, tertiary), $_rest-rustic-light);

$m3-rustic-light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary-rustic-light,
    tertiary: $_tertiary-rustic-light,
    use-system-variables: true,
  ),
  typography: (
    brand-family: 'Segoe UI',
    bold-weight: 900,
    use-system-variables: true,
  ),
  density: (
    scale: 0
  )
));

.rustic-light-theme {
  --sys-color-scheme: dark;
  --sys-primary: rgb(138 80 34);
  --sys-surface-tint: rgb(138 80 34);
  --sys-on-primary: rgb(255 255 255);
  --sys-primary-container: rgb(255 220 198);
  --sys-on-primary-container: rgb(48 20 0);
  --sys-secondary: rgb(117 88 69);
  --sys-on-secondary: rgb(255 255 255);
  --sys-secondary-container: rgb(255 220 198);
  --sys-on-secondary-container: rgb(43 23 8);
  --sys-tertiary: rgb(95 97 53);
  --sys-on-tertiary: rgb(255 255 255);
  --sys-tertiary-container: rgb(228 230 174);
  --sys-on-tertiary-container: rgb(27 29 0);
  --sys-error: rgb(186 26 26);
  --sys-on-error: rgb(255 255 255);
  --sys-error-container: rgb(255 218 214);
  --sys-on-error-container: rgb(65 0 2);
  --sys-background: rgb(255 248 245);
  --sys-on-background: rgb(34 26 21);
  --sys-surface: rgb(255 255 255);
  --sys-on-surface: rgb(34 26 21);
  --sys-surface-variant: rgb(243 222 210);
  --sys-on-surface-variant: rgb(82 68 59);
  --sys-outline: rgb(132 116 106);
  --sys-outline-variant: rgb(214 195 183);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(56 47 41);
  --sys-inverse-on-surface: rgb(254 237 228);
  --sys-inverse-primary: rgb(255 183 132);
  --sys-primary-fixed: rgb(255 220 198);
  --sys-on-primary-fixed: rgb(48 20 0);
  --sys-primary-fixed-dim: rgb(255 183 132);
  --sys-on-primary-fixed-variant: rgb(109 57 12);
  --sys-secondary-fixed: rgb(255 220 198);
  --sys-on-secondary-fixed: rgb(43 23 8);
  --sys-secondary-fixed-dim: rgb(228 191 168);
  --sys-on-secondary-fixed-variant: rgb(91 65 47);
  --sys-tertiary-fixed: rgb(228 230 174);
  --sys-on-tertiary-fixed: rgb(27 29 0);
  --sys-tertiary-fixed-dim: rgb(200 202 148);
  --sys-on-tertiary-fixed-variant: rgb(71 73 31);
  --sys-surface-dim: rgb(231 215 206);
  --sys-surface-bright: rgb(255 248 245);
  --sys-surface-container-lowest: rgb(255 255 255);
  --sys-surface-container-low: rgb(255 241 234);
  --sys-surface-container: rgb(251 235 226);
  --sys-surface-container-high: rgb(246 229 220);
  --sys-surface-container-highest: rgb(240 223 214);

  --dq-neutral-variant: rgb(252, 252, 252);
  --dq-inactive-contrast: rgb(235, 235, 235);
  --dq-bright-contrast: rgb(255, 255, 255);
  --dq-box-shadow-color: rgba(0, 0, 0, 0.08);

  --dq-app-color-background: #F8F8F8; /* Dark grey */
  --dq-app-color-warning: #FFFBDD; /* Muted yellow */
  --dq-app-color-surface: #FDF6F2; /* Warm brown */
  --dq-app-color-error: #FFE6E6; /* Muted red */
  --dq-app-color-success: #F3FDF2; /* Dark green */
  --dq-app-color-accent: #ECFFEB; /* Brighter green */

}
