@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$_palettes-polar-night: (
  primary: (
    0: #000000,
    10: #001C37,
    20: #033258,
    25: #153D64,
    30: #234970,
    35: #30547D,
    40: #3D608A,
    50: #5679A4,
    60: #7093BF,
    70: #8BAEDB,
    80: #A6C9F8,
    90: #D2E4FF,
    95: #EAF1FF,
    98: #F8F9FF,
    99: #FDFCFF,
    100: #FFFFFF
  ),
  secondary: (
    0: #000000,
    10: #161C27,
    20: #2B313C,
    25: #363C48,
    30: #414753,
    35: #4D525F,
    40: #585E6C,
    50: #717785,
    60: #8B919F,
    70: #A5ABBA,
    80: #C1C6D6,
    90: #DDE2F2,
    95: #ECF0FF,
    98: #F9F9FF,
    99: #FDFBFF,
    100: #FFFFFF
  ),
  tertiary: (
    0: #000000,
    10: #111C2D,
    20: #273143,
    25: #323C4E,
    30: #3D475A,
    35: #495366,
    40: #555F73,
    50: #6D778C,
    60: #8791A7,
    70: #A1ABC2,
    80: #BCC7DE,
    90: #D8E3FB,
    95: #ECF0FF,
    98: #F9F9FF,
    99: #FDFBFF,
    100: #FFFFFF
  ),
  neutral: (
    0: #000000,
    10: #191c18,
    20: #2e312d,
    25: #393c38,
    30: #454843,
    35: #50534e,
    40: #5c5f5a,
    50: #8f918c,
    60: #8f918c,
    70: #aaaca6,
    80: #c5c7c1,
    90: #e1e3dd,
    95: #f0f1eb,
    98: #f9faf3,
    99: #fbfdf6,
    100:#FFFFFF
  ),
  neutral-variant: (
    0: #000000,
    10: #171d16,
    20: #2b322a,
    25: #363d35,
    30: #414940,
    35: #4d544b,
    40: #596057,
    50: #72796f,
    60: #8b9388,
    70: #a6ada2,
    80: #c1c9bd,
    90: #dde5d8,
    95: #ecf3e6,
    98: #f4fcef,
    99: #f7fff2,
    100: #FFFFFF
  ),
  error: (
    0: #000000,
    10: #3f0017,
    20: #66002a,
    25: #7a0034,
    30: #90003e,
    35: #a50049,
    40: #bb0054,
    50: #e3216b,
    60: #ff4c85,
    70: #ff85a3,
    80: #ffb1c1,
    90: #ffd9df,
    95: #ffecee,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest-polar-night: (
  secondary: map.get($_palettes-polar-night, secondary),
  neutral: map.get($_palettes-polar-night, neutral),
  neutral-variant: map.get($_palettes-polar-night, neutral-variant),
  error: map.get($_palettes-polar-night, tertiary),
);

$_primary-polar-night: map.merge(map.get($_palettes-polar-night, primary), $_rest-polar-night);
$_tertiary-polar-night: map.merge(map.get($_palettes-polar-night, tertiary), $_rest-polar-night);

$m3-polar-night-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary-polar-night,
    tertiary: $_tertiary-polar-night,
    use-system-variables: true,
  ),
  typography: (
    brand-family: 'Segoe UI',
    bold-weight: 900,
    use-system-variables: true,
  ),
  density: (
    scale: 0
  )
));

.polar-night-theme {
  --sys-color-scheme: dark;
  --sys-primary: rgb(166 201 248);
  --sys-surface-tint: rgb(166 201 248);
  --sys-on-primary: rgb(3 50 88);
  --sys-primary-container: rgb(84 119 162);
  --sys-on-primary-container: rgb(255 255 255);
  --sys-secondary: rgb(193 198 214);
  --sys-on-secondary: rgb(43 49 60);
  --sys-secondary-container: rgb(37 43 55);
  --sys-on-secondary-container: rgb(177 183 198);
  --sys-tertiary: rgb(188 199 222);
  --sys-on-tertiary: rgb(39 49 67);
  --sys-tertiary-container: rgb(66 76 96);
  --sys-on-tertiary-container: rgb(224 233 255);
  --sys-error: rgb(255 180 171);
  --sys-on-error: rgb(105 0 5);
  --sys-error-container: rgb(147 0 10);
  --sys-on-error-container: rgb(255 218 214);
  --sys-background: rgb(18 19 22);
  --sys-on-background: rgb(226 226 230);
  --sys-surface: rgb(16 20 15);
  --sys-on-surface: rgb(226 226 230);
  --sys-surface-variant: rgb(67 71 78);
  --sys-on-surface-variant: rgb(195 198 207);
  --sys-outline: rgb(141 145 153);
  --sys-outline-variant: rgb(67 71 78);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(226 226 230);
  --sys-inverse-on-surface: rgb(47 48 51);
  --sys-inverse-primary: rgb(61 96 138);
  --sys-primary-fixed: rgb(210 228 255);
  --sys-on-primary-fixed: rgb(0 28 55);
  --sys-primary-fixed-dim: rgb(166 201 248);
  --sys-on-primary-fixed-variant: rgb(35 73 112);
  --sys-secondary-fixed: rgb(221 226 242);
  --sys-on-secondary-fixed: rgb(22 28 39);
  --sys-secondary-fixed-dim: rgb(193 198 214);
  --sys-on-secondary-fixed-variant: rgb(65 71 83);
  --sys-tertiary-fixed: rgb(216 227 251);
  --sys-on-tertiary-fixed: rgb(17 28 45);
  --sys-tertiary-fixed-dim: rgb(188 199 222);
  --sys-on-tertiary-fixed-variant: rgb(61 71 90);
  --sys-surface-dim: rgb(18 19 22);
  --sys-surface-bright: rgb(56 57 60);
  --sys-surface-container-lowest: rgb(13 14 17);
  --sys-surface-container-low: rgb(26 28 30);
  --sys-surface-container: rgb(30 32 35);
  --sys-surface-container-high: rgb(40 42 45);
  --sys-surface-container-highest: rgb(51 53 56);

  --dq-neutral-variant: rgb(38, 43, 36);
  --dq-inactive-contrast: rgb(44, 50, 42);
  --dq-bright-contrast: rgb(54 58 52);
  --dq-box-shadow-color: rgba(255, 255, 255, 0.2);

  --dq-app-color-background: #1E1E1E; /* Dark grey */
  --dq-app-color-warning: #4A4530; /* Muted yellow */
  --dq-app-color-surface: #2E2A27; /* Warm brown */
  --dq-app-color-error: #4A2A2A; /* Muted red */
  --dq-app-color-success: #29432A; /* Dark green */
  --dq-app-color-accent: #334D33; /* Brighter green */
}
