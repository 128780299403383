@use "@angular/material" as mat;

@import "@angular/material";

@mixin dq-extra-theme($theme) {

  @each $hue in (0,
  10,
  20,
  25,
  30,
  35,
  40,
  50,
  60,
  70,
  80,
  90,
  95,
  98,
  99,
  100) {
    .primary-contrast-#{$hue} {
      $hue-color:  mat.get-theme-color($theme, primary, $hue);
      background-color: $hue-color !important;
      color: hsl(from $hue-color h s mod(l + 50, 100))!important;
    }
    .secondary-contrast-#{$hue} {
      $hue-color:  mat.get-theme-color($theme, secondary, $hue);
      background-color: $hue-color !important;
      color: hsl(from $hue-color h s mod(l + 50, 100))!important;
    }
    .tertiary-contrast-#{$hue} {
      $hue-color:  mat.get-theme-color($theme, tertiary, $hue);
      background-color: $hue-color !important;
      color: hsl(from $hue-color h s mod(l + 50, 100))!important;
    }
    .error-contrast-#{$hue} {
      $hue-color:  mat.get-theme-color($theme, error, $hue);
      background-color: $hue-color !important;
      color: hsl(from $hue-color h s mod(l + 50, 100))!important;
    }
    .primary-hover-#{$hue}:hover {
      $hue-color:  mat.get-theme-color($theme, primary, $hue);
      background-color: $hue-color !important;
      color: hsl(from $hue-color h s mod(l + 50, 100))!important;
    }
    .secondary-hover-#{$hue}:hover {
      $hue-color:  mat.get-theme-color($theme, secondary, $hue);
      background-color: $hue-color !important;
      color: hsl(from $hue-color h s mod(l + 50, 100))!important;
    }
    .tertiary-hover-#{$hue}:hover {
      $hue-color:  mat.get-theme-color($theme, tertiary, $hue);
      background-color: $hue-color !important;
      color: hsl(from $hue-color h s mod(l + 50, 100))!important;
    }
    .error-hover-#{$hue}:hover {
      $hue-color:  mat.get-theme-color($theme, primary, $hue);
      background-color: $hue-color !important;
      color: hsl(from $hue-color h s mod(l + 50, 100))!important;
    }
  }

  @each $hue in (
    0,
    10,
    20,
    25,
    30,
    35,
    40,
    50,
    60,
    70,
    80,
    90,
    95,
    98,
    99,
    100
  ) {
    .primary-stroked-contrast-#{$hue} {
      $hue-color: mat.get-theme-color($theme, primary, $hue);
      color: $hue-color!important;
      
      &:hover {
        background-color: var(--sys-primary)!important;
      }
    }
    
    .secondary-stroked-contrast-#{$hue} {
      $hue-color: mat.get-theme-color($theme, secondary, $hue);
      color: $hue-color!important;
      
      &:hover {
        background-color: var(--sys-secondary)!important;
      }
    }
    
    .tertiary-stroked-contrast-#{$hue} {
      $hue-color: mat.get-theme-color($theme, tertiary, $hue);
      color: $hue-color!important;
      
      &:hover {
        background-color: mat.get-theme-color($theme, tertiary, 90)!important;
      }
    }
    
    .error-stroked-contrast-#{$hue} {
      $hue-color: mat.get-theme-color($theme, error, $hue);
      color: $hue-color!important;
      
      &:hover {
        background-color: mat.get-theme-color($theme, error, 90)!important;
      }
    }
  }

  @each $hue in (0, 10, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 95, 98, 99, 100) {
    .spinner-primary-contrast-#{$hue} {
      $hue-color: mat.get-theme-color($theme, primary, $hue);
      circle {
        stroke: $hue-color !important;
      }
    }
  
    .spinner-secondary-contrast-#{$hue} {
      $hue-color: mat.get-theme-color($theme, secondary, $hue);
      circle {
        stroke: $hue-color !important;
      }
    }
  
    .spinner-tertiary-contrast-#{$hue} {
      $hue-color: mat.get-theme-color($theme, tertiary, $hue);
      circle {
        stroke: $hue-color !important;
      }
    }

    .spinner-error-contrast-#{$hue} {
      $hue-color: mat.get-theme-color($theme, error, $hue);
      circle {
        stroke: $hue-color !important;
      }
    }
  }
}
