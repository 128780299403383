mat-snack-bar-container {
  overflow: hidden;
  --mat-snack-bar-button-color: var(--sys-on-surface);
  --mdc-snackbar-supporting-text-color: var(--sys-on-surface);
  --mdc-snackbar-container-color: var(--sys-surface);


  &.mat-error, {
    --mdc-snackbar-container-color: var(--sys-error-container);
  }

  &.mat-success {
    --mdc-snackbar-container-color: var(--sys-primary-container);
  }
}
