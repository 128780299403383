.no-subscript-wrapper .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.dq-box-shadow {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid var(--sys-primary);
  background-color: var(--sys-surface);
  border-radius: 8px;
}

.dq-toggle-inactive-btn {
  background-color: gray !important;
  color: white !important;
}

.search-input {
  input {
    background-color: var(--sys-surface);
    border: 0.5px solid var(--sys-outline);
    color: var(--sys-on-surface);
  }
}

.dq-mat-form-field-wrapper {
  margin-bottom: -1.25em;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.dq-flex {
  display: flex;
  flex-wrap: wrap;
}

.dq-row {
  display: flex;
  flex-wrap: wrap;
}

.dq-flex-aligned {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dq-flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

::-webkit-scrollbar {
  border: 2px solid #fafafa;
  /* should match background, can't be transparent */
  background-color: #fafafa;
}

::-webkit-scrollbar:vertical {
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #fafafa;
  /* should match background, can't be transparent */
  background-color: rgba(150, 150, 150, 0.8);
}

.m-fadeOut {
  height: 0;
  opacity: 0;
  transition: all 0s ease 300ms, opacity 300ms;
}

.m-fadeIn {
  height: auto;
  opacity: 1;
  transition: all 0s ease 0s, opacity 300ms;
}

.pill-blue {
  background-color: #4354bc;
  color: white;
}

.pill-green {
  background-color: #00a581;
  color: white;
}

.pill-yellow {
  background-color: #f2bb0c;
  color: black;
}

.pill-orange {
  background-color: #ff8970;
  color: white;
}

.pill-pink {
  background-color: #ffdd19;
  color: white;
}

.pill-purple {
  background-color: #ad67b8;
  color: white;
}

.pill-gray {
  background-color: var(--sys-outline);
  color: white;
}

//NGX Mat Select Search
.contains-mat-select-search {
  position: sticky !important;
  padding: 0;
  z-index: 1;
  top: 0;
  opacity: 1;
}

.input-no-margin {
  .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
}
