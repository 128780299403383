@use "@angular/material" as mat;
@import "@angular/material";

@mixin dq-client-dialog-component-theme($theme) {
  dq-client-dialog {
      .layout-container {
        background-color: var(--sys-surface-bright);
      }

      .top-navigation-btn:hover {
        background-color: var(--sys-surface-container-high);
        color: var(--sys-on-surface);
      }

      .top-navigation-btn-active {
        background-color: var(--sys-surface-container-high);
        color: var(--sys-on-surface);
      }

      .application-navigation-btn-active {
        background-color: var(--dq-neutral-variant);
      }
    }

    .dq-client-dialog-rental-application-form-add-new-component {
      .container {
        .form-item-selected,
        .form-item:hover {
          background-color: var(--sys-surface);
        }
      }
    }

    .sub-container {
      color: var(--sys-secondary);
    }

    .client-dialog-inventory-component {
      .unit-row:hover {
        background-color: var(--sys-surface-container-high);
        color:  var(--sys-on-surface);
      }

      .unit-table-header {
        background-color: var(--sys-surface-container-high);
        color: var(--sys-on-surface);
      }
    }

    .ngx-pagination .current {
      background: var(--sys-surface-container-high);
    }

    .tag-div,
    .preference-div,
    .rep-div,
    .building-div,
    .source-div {
      background: var(--sys-surface-container-high);
      color: var(--sys-on-surface);
    }

    .tag-div:hover,
    .building-div:hover,
    .preference-div:hover,
    .rep-div:hover {
      background: var(--sys-surface-container-high)!important;
      color: var(--sys-on-surface)!important;
    }

    .dq-client-dialog-user-alerts-component {
      .listing-alert-tag {
        background-color: var(--sys-primary);
      }
    }

    dq-client-dialog-task {
      .task-done-item {
        background-color: var(--dq-neutral-variant);
      }
    }

    .client-dialog-user-general-component {
      .contact-info-detail-label {
        border: 1px solid var(--sys-primary);
        margin-right: 15px;
      }

      .contact-info-detail-label:hover {
        background-color: var(--sys-surface);
      }

    }

    .client-dialog-quick-text-message-component {
      .template-div {
        background-color: var(--sys-surface-container-high);
      }

      .template-div:hover {
        background-color: var(--sys-surface-container-high);
      }
    }

    .client-dialog-email-component {
      .template-div {
        background-color: var(--sys-surface-container-high);
      }

      .template-div:hover {
        background-color: var(--sys-surface-container-high);
      }
    }

    .client-dialog-rental-application-rental-item-add-component {
      .rental-type-div:hover {
        background-color: var(--dq-neutral-variant);
      }
    }

}
