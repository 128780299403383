@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$_palettes-cyan-light: (
  primary: (
    0: #000000,
    10: #002020,
    20: #003737,
    25: #004343,
    30: #004F4F,
    35: #005C5C,
    40: #006A6A,
    50: #008585,
    60: #00A1A1,
    70: #00BEBE,
    80: #00DDDD,
    90: #8FF3F2,
    95: #ADFFFE,
    98: #E2FFFE,
    99: #F1FFFE,
    100: #FFFFFF
  ),
  secondary: (
    0: #000000,
    10: #002020,
    20: #123635,
    25: #1E4141,
    30: #2A4C4C,
    35: #365858,
    40: #426464,
    50: #5B7D7D,
    60: #749797,
    70: #8EB2B1,
    80: #A9CDCD,
    90: #C5EAE9,
    95: #D3F8F7,
    98: #E2FFFE,
    99: #F1FFFE,
    100: #FFFFFF
  ),
  tertiary: (
    0: #000000,
    10: #001C38,
    20: #123153,
    25: #1F3D5F,
    30: #2B486B,
    35: #385477,
    40: #446084,
    50: #5D799E,
    60: #7693B9,
    70: #91ADD5,
    80: #ACC8F2,
    90: #D3E4FF,
    95: #EAF1FF,
    98: #F8F9FF,
    99: #FDFCFF,
    100: #FFFFFF
  ),
  neutral: (
    0: #000000,
    10: #191C1C,
    20: #2D3131,
    25: #383C3C,
    30: #444747,
    35: #4F5353,
    40: #5B5F5F,
    50: #747877,
    60: #8E9191,
    70: #A9ACAB,
    80: #C4C7C6,
    90: #E0E3E2,
    95: #EFF1F0,
    98: #F7FAF9,
    99: #FAFDFC,
    100: #FFFFFF
  ),
  neutral-variant: (
    0: #000000,
    10: #141D1D,
    20: #293232,
    25: #343D3D,
    30: #3F4948,
    35: #4A5454,
    40: #566060,
    50: #6F7979,
    60: #889392,
    70: #A3ADAD,
    80: #BEC9C8,
    90: #DAE5E4,
    95: #E9F3F2,
    98: #F1FBFA,
    99: #F4FEFD,
    100: #FFFFFF
  ),
  error: (
    0: #000000,
    10: #3f0017,
    20: #66002a,
    25: #7a0034,
    30: #90003e,
    35: #a50049,
    40: #bb0054,
    50: #e3216b,
    60: #ff4c85,
    70: #ff85a3,
    80: #ffb1c1,
    90: #ffd9df,
    95: #ffecee,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest-cyan-light: (
  secondary: map.get($_palettes-cyan-light, secondary),
  neutral: map.get($_palettes-cyan-light, neutral),
  neutral-variant: map.get($_palettes-cyan-light, neutral-variant),
  error: map.get($_palettes-cyan-light, tertiary),
);

$_primary-cyan-light: map.merge(map.get($_palettes-cyan-light, primary), $_rest-cyan-light);
$_tertiary-cyan-light: map.merge(map.get($_palettes-cyan-light, tertiary), $_rest-cyan-light);

$m3-cyan-light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary-cyan-light,
    tertiary: $_tertiary-cyan-light,
    use-system-variables: true,
  ),
  typography: (
    brand-family: 'Segoe UI',
    bold-weight: 900,
    use-system-variables: true,
  ),
  density: (
    scale: 0
  )
));

.cyan-light-theme {
  --sys-color-scheme: light;
  --sys-primary: rgb(0 106 106);
  --sys-surface-tint: rgb(0 106 106);
  --sys-on-primary: rgb(255 255 255);
  --sys-primary-container: rgb(156 241 240);
  --sys-on-primary-container: rgb(0 32 32);
  --sys-secondary: rgb(74 99 99);
  --sys-on-secondary: rgb(255 255 255);
  --sys-secondary-container: rgb(204 232 231);
  --sys-on-secondary-container: rgb(5 31 31);
  --sys-tertiary: rgb(75 96 124);
  --sys-on-tertiary: rgb(255 255 255);
  --sys-tertiary-container: rgb(211 228 255);
  --sys-on-tertiary-container: rgb(4 28 53);
  --sys-error: rgb(186 26 26);
  --sys-on-error: rgb(255 255 255);
  --sys-error-container: rgb(255 218 214);
  --sys-on-error-container: rgb(65 0 2);
  --sys-background: rgb(244 251 250);
  --sys-on-background: rgb(22 29 29);
  --sys-surface: rgb(255 255 255);
  --sys-on-surface: rgb(22 29 29);
  --sys-surface-variant: rgb(218 229 228);
  --sys-on-surface-variant: rgb(63 73 72);
  --sys-outline: rgb(111 121 121);
  --sys-outline-variant: rgb(190 201 200);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(43 50 49);
  --sys-inverse-on-surface: rgb(236 242 241);
  --sys-inverse-primary: rgb(128 213 212);
  --sys-primary-fixed: rgb(156 241 240);
  --sys-on-primary-fixed: rgb(0 32 32);
  --sys-primary-fixed-dim: rgb(128 213 212);
  --sys-on-primary-fixed-variant: rgb(0 79 79);
  --sys-secondary-fixed: rgb(204 232 231);
  --sys-on-secondary-fixed: rgb(5 31 31);
  --sys-secondary-fixed-dim: rgb(176 204 203);
  --sys-on-secondary-fixed-variant: rgb(50 75 75);
  --sys-tertiary-fixed: rgb(211 228 255);
  --sys-on-tertiary-fixed: rgb(4 28 53);
  --sys-tertiary-fixed-dim: rgb(179 200 232);
  --sys-on-tertiary-fixed-variant: rgb(51 72 99);
  --sys-surface-dim: rgb(213 219 218);
  --sys-surface-bright: rgb(244 251 250);
  --sys-surface-container-lowest: rgb(255 255 255);
  --sys-surface-container-low: rgb(239 245 244);
  --sys-surface-container: rgb(233 239 238);
  --sys-surface-container-high: rgb(227 233 233);
  --sys-surface-container-highest: rgb(221 228 227);

  --dq-neutral-variant: rgb(252, 252, 252);
  --dq-inactive-contrast: rgb(235, 235, 235);
  --dq-bright-contrast: rgb(255, 255, 255);
  --dq-box-shadow-color: rgba(0, 0, 0, 0.08);

  --dq-app-color-background: #F8F8F8; /* Dark grey */
  --dq-app-color-warning: #FFFBDD; /* Muted yellow */
  --dq-app-color-surface: #FDF6F2; /* Warm brown */
  --dq-app-color-error: #FFE6E6; /* Muted red */
  --dq-app-color-success: #F3FDF2; /* Dark green */
  --dq-app-color-accent: #ECFFEB; /* Brighter green */

}
