.alert-danger {
  background-color: var(--sys-surface-container-highest);
  border-color: var(--sys-primary);
}


//.alert-danger {
//  background-color: var(--sys-error-container);
//  border: 1px solid var(--sys-error);
//  color: var(--sys-error);
//}

.alert {
  font-size: 1rem;
  padding-block: 1lh;
  padding-inline: 1ch;

  &.error,
  &.warning {
    background-color: var(--sys-error-container);
    border: 1px solid var(--sys-error);
    color: var(--sys-error);
  }
}
