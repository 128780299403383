@use '@angular/material' as mat;
@import '@angular/material';

@mixin dq-lead-queue-component-theme($theme) {
  
  .list-name-div :hover {
    color: var(--sys-primary);
  }

  .lead-queue-drop-down-item :hover {
    .list-name {
      color: var(--sys-primary);
    }
  }

  .lead-queue-add-filter-div {
    span {
      color: var(--sys-primary);
    }
  }

  .follow-up-booss-lead-filter-building-component {
    .building-div-wrapper :hover {
      background: var(--sys-primary);
      color: var(--sys-secondary);
    }

    .tag-div:hover {
      background: var(--sys-surface-container-high)!important;
      color: var(--sys-on-surface)!important;
    }
  }

  .lead-queue-leads-component {
    td.name-col :hover {
      color: var(--sys-secondary);
    }

    .search-icon {
      position: absolute;
      left: 10px;
      color: var(--sys-secondary);
    }

    .open-icon {
      color: var(--sys-primary);
    }
  }

  .lead-queue-tags-component {
    .tag-div:hover {
      background: var(--sys-surface-container-high)!important;
      color: var(--sys-on-surface)!important;
    }
  }
}
