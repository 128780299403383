mat-option {
  & > span {
    &:has(dq-custom-audio-player) {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.mat-option-disabled {
    opacity: 0.5;
    background-color: var(--dq-inactive-contrast);
  }
}
